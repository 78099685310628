import React from 'react';
import { registerLocale } from 'react-datepicker';

import pt from 'date-fns/locale/pt';

import 'react-datepicker/dist/react-datepicker.css';

import { DateInput } from './styles';

export default function Componente({ selected, setSelected }) {
  registerLocale('pt', pt);
  return (
    <DateInput
      selected={selected}
      locale="pt"
      onChange={(date) => setSelected(date)}
      dateFormat="dd/MM/yyyy"
    />
  );
}
