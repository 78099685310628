import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Container } from 'react-bootstrap';

export const ElementScroll = styled(Element)`
  overflow: scroll;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  margin-left: 5%;
`;

export const ContainerContent = styled(Container)`
  margin-top: 120px;
  margin-bottom: 40px;
`;
