const definirFormaPagamento = (forma) => {
  let string;
  switch (forma) {
    case 'ticket':
      string = 'Boleto';
      break;
    case 'credit_company':
      string = 'Lojista';
      break;
    case 'credit_customer':
      string = 'Cliente';
      break;
    default:
      string = 'Desconhecido';
      break;
  }

  return string;
};

export default definirFormaPagamento;
