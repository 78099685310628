import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import api from '../../services/api';
import './styles.css';

import PageBase from '../../components/PageBase';
import ModalArquivos from '../../components/ModalArquivos';
import ModalLoad from '../../components/ModalLoad';

export default function DetalhesPagamento({ match }) {
  const [codigo, setCodigo] = useState(0);
  const [dateTime, setDateTime] = useState('');
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState('');
  const [telefone, setTelefone] = useState('');
  const [valor, setValor] = useState(0);
  const [showModalFile, setShowModalFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [showModalLoad, setShowModalLoad] = useState(false);
  const [conta, setConta] = useState();

  useEffect(() => {
    async function loadPagamento() {
      setShowModalLoad(true);
      const { id } = match.params;

      const { data, status: httpStatus } = await api.get(`/tickets/${id}`);
      if (httpStatus !== 200) return;
      setConta(data);

      setDateTime(data.createdAt);
      setCodigo(data.id);
      setNome(data.customerName);
      setStatus(data.ticketStatus);
      setTelefone(data.customerPhoneNumber);
      setValor(data.amountTicket);
      setFiles(data.filesUrl || []);
      setShowModalLoad(false);
    }
    loadPagamento();
  }, [match.params]);

  if (!conta) return <></>;

  function formatarData(value) {
    const date = new Date(value);

    return date.toLocaleDateString('pt-BR');
  }

  async function attStatus(statusPagamento, codigoPagamento) {
    await api.put(`/conta/${codigoPagamento}`, { status: statusPagamento });
  }
  function mostrarArquivos(values) {
    setFiles(values);
    setShowModalFile(true);
  }

  function verficarStatusByCompany(value) {
    switch (Number(value)) {
      case 1:
        return 'aguardando';
      case 2:
        return 'pago';
      case 3:
        return 'negado';
      case 4:
        return 'expirado';
      default:
        return 'Pagamento na negociação';
    }
  }

  return (
    <PageBase>
      <ModalLoad show={showModalLoad} />
      <Row>
        <Col>
          <h1>{`Detalhes pagamento código ${codigo}`}</h1>
          <hr />
          <Button variant="success">
            <Link to="/pagamentos" style={{ color: '#fff', textDecoration: 'none' }}>
              Voltar
            </Link>
          </Button>
        </Col>
      </Row>
      {conta.companyId && (
        <p id="nothing">Realizado por um parceiro, não a nada para fazer aqui!</p>
      )}
      <Row style={{ marginTop: '25px' }}>
        <Col>
          <Form>
            <Row>
              <Col>
                <Form.Label>Data e Hora</Form.Label>
                <Form.Control disabled value={formatarData(dateTime)} />
              </Col>
              <Col>
                <Form.Label>Status Pagamento</Form.Label>
                <Form.Control
                  disabled
                  value={verficarStatusByCompany(conta.transactionPaymentStatus)}
                />
              </Col>
              <Col>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  // disabled={Number(conta.ticketPaymentStatus) === 2 || conta.companyId}
                  disabled
                >
                  <option value={1}>pendente</option>
                  {conta.companyId && Number(conta.transactionPaymentStatus) === 2
                   && (<option value={2}>sucesso</option>)}
                  <option value={3}>cancelado</option>
                </Form.Control>
              </Col>
              <Col style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
              >
                <Button
                  variant="success"
                  style={{ height: '40px' }}
                  // disabled={Number(conta.ticketPaymentStatus) === 2 || conta.companyId}
                  disabled
                  onClick={() => attStatus(status, codigo)}
                >
                  Salvar Status
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled value={nome} />
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Label>Telefone</Form.Label>
                <Form.Control disabled value={telefone} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Valor Boleto</Form.Label>
                <Form.Control disabled value={`R$ ${valor.toFixed(2)}`} />
              </Col>
            </Row>
            {/*
            {!conta.companyId && (
            <>

              <Row>
                <Col>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control disabled value={conta.cep} />
                </Col>
                <Col>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control disabled value={conta.rua} />
                </Col>
                <Col>
                  <Form.Label>Número</Form.Label>
                  <Form.Control disabled value={conta.numero} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control disabled value={conta.bairro} />
                </Col>
                <Col>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control disabled value={conta.cidade} />
                </Col>
                <Col>
                  <Form.Label>UF</Form.Label>
                  <Form.Control disabled value={conta.uf} />
                </Col>
              </Row>
            </>
            )} */}
            <Row>
              <Col style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
              >
                {/* {!conta.companyId && (
                  <> */}
                <Button
                  variant="primary"
                  style={{ height: '40px' }}
                  onClick={() => {
                    mostrarArquivos(files);
                  }}
                  disabled={!conta.document && files.length <= 0}
                >
                  Arquivos
                </Button>

                {/* </>
                )} */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {Number(conta.shipping_type) !== 2 && (conta.document || files.length > 0) ? (
        <ModalArquivos
          show={showModalFile}
          onHide={() => setShowModalFile(false)}
          files={[conta.document, ...files]}
        />
      ) : (<></>)}
    </PageBase>
  );
}
