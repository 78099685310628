import { useState, useEffect } from 'react';
import api from '../services/api';

export function useFetchCompanies({ likeSocialReason }) {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await api.get('/companies', {
          params: { likeSocialReason: likeSocialReason.toLowerCase() },
        });
        if (status === 200) setCompanies(data.companies);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [likeSocialReason]);

  return {
    companies,
    loading,
  };
}


export function useFetchCompanyById({ id }) {
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data, status } = await api.get(`/companies/${id}` );
        if (status === 200) setCompany(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return {
    company,
    loading,
  };
}