import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import {
  Modal, Button, Form, Row, Col,
} from 'react-bootstrap';
import { TextError } from './styles';
import api from '../../services/api';
import validateCpfCnpj from '../../utils/validateCpfCnpj';

import cadLoad from '../../animations/cadLoading.json';

import bancos from '../../utils/bancos.json';

import InputMask from '../InputMask';

export default function ModalLoad({
  close, cancel, empresa, ...rest
}) {
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [natureza, setNatureza] = useState('');
  const [cpfCnpjBanco, setCpfCnpjBanco] = useState('');
  const [holderType, setHolderType] = useState('');
  const [pix, setPix] = useState('');
  const [banco, setBanco] = useState('');

  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-99');

  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!validateCpfCnpj(cpfCnpjBanco)) {
      setLoading(false);
      return setError('CNPJ/CPF está inválido');
    }

    setError('');

    const { nome: nomeBanco } = bancos[banco];

    try {
      const { status } = await api.put(`/companies/${empresa.id}`, {
        phoneNumber: empresa.phoneNumber,
        email: empresa.email,
        address: empresa.address.address,
        zipCode: empresa.address.zipCode,
        city: empresa.address.city,
        neighborhood: empresa.address.neighborhood,
        number: empresa.address.number,
        state: empresa.address.state,
        rateTableId: empresa.rateTableId,
        agency: String(agencia),
        account: String(conta),
        bank: nomeBanco,
        accountType: natureza,
        dataBankDocument: cpfCnpjBanco,
        holderType,
        pix: String(pix),

      });

      if (status !== 202) {
        setLoading(false);
        return setError('Houve um erro inesperado, tente novamente!');
      }

      setAgencia('');
      setConta('');
      setNatureza('');
      setCpfCnpjBanco('');
      setPix('');
      setBanco('');

      setLoading(false);

      return close();
    } catch (err) {
      setLoading(false);
      return setError('Houve um erro inesperado, tente novamente!');
    }
  }

  useEffect(() => {
    if (holderType === 'f') setMaskCpfCnpj('999.999.999-99');
    else if (holderType === 'j') setMaskCpfCnpj('99.999.999/9999-99');
  }, [holderType]);

  return (
    <Modal
      {...rest}
      centered
      size="lg"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Dados bancários ${empresa.fantasyName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Agência</Form.Label>
                <Form.Control
                  required
                  placeholder="Agência"
                  value={agencia}
                  onChange={(e) => setAgencia(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Número da conta</Form.Label>
                <Form.Control
                  required
                  placeholder="Número da conta"
                  value={conta}
                  onChange={(e) => setConta(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Natureza</Form.Label>
                <Form.Control
                  required
                  placeholder="Natureza"
                  value={natureza}
                  onChange={(e) => setNatureza(e.target.value)}
                  size="sm"
                  as="select"
                >
                  <option value="">Selecione</option>
                  <option value="checking account">Conta corrente</option>
                  <option value="savings account">Conta Poupança</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>CPF/CNPJ</Form.Label>
                <Form.Control
                  required
                  value={holderType}
                  onChange={(e) => setHolderType(e.target.value)}
                  style={{ width: '100%', maxWidth: 'none' }}
                  as="select"
                  size="sm"
                  id="select-bancos"
                >
                  <option value="">Selecione</option>
                  <option value="f">CPF</option>
                  <option value="j">CNPJ</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>CPF/CNPJ</Form.Label>
                <InputMask
                  mask={maskCpfCnpj}
                  placeholder="CPF/CNPJ"
                  value={cpfCnpjBanco}
                  onChange={(e) => setCpfCnpjBanco(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Banco</Form.Label>
                <Form.Control
                  required
                  value={banco}
                  onChange={(e) => setBanco(e.target.value)}
                  style={{ width: '100%', maxWidth: 'none' }}
                  as="select"
                  size="sm"
                  id="select-bancos"
                >
                  <option value="">Selecione</option>
                  {bancos.map((b, index) => (
                    <option key={b.num} value={index}>{`[${b.num}] - ${b.nome}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>PIX</Form.Label>
                <Form.Control
                  required
                  placeholder="PIX"
                  value={pix}
                  onChange={(e) => setPix(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <TextError>{error}</TextError>
          </Col>
          <Button
            variant="secondary"
            onClick={() => {
              setAgencia('');
              setConta('');
              // setNatureza('');
              setBanco(null);
              // setCpfCnpjBanco('');
              cancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="info"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Lottie
                options={{
                  animationData: cadLoad,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
                height={25}
                width={80}
              />
            ) : ('Cadastrar')}

          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
