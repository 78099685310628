import React from 'react';
import {
  Table, Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import lupa from '../../assets/lupa.svg';

import './styles.css';

function formatarData(value) {
  const date = new Date(value);

  return `${date.toLocaleDateString('pt-BR')}`;
}

function verificarStatus(value) {
  if (value === 'waiting') return 'pendente';
  if (value === 'done') return 'sucesso';
  if (value === 'canceled') return 'cancelado';
  // if (Number(value) === 4) return 'cancelado';
  return '';
}

function verficarStatusByCompany(value) {
  switch (value) {
    case 'waiting':
      return 'aguardando';
    case 'success':
      return 'pago';
    case 'denied':
      return 'negado';
    case 'expired':
      return 'expirado';
    default:
      return 'Não definido';
  }
}

function RowPayment({ pagamento }) {
  return (
    <tr key={pagamento.id}>
      <td>{pagamento.id}</td>
      <td key={pagamento.id}>{formatarData(pagamento.createdAt)}</td>
      {/* <td>{pagamento.nome}</td> */}
      <td>{verificarStatus(pagamento.ticketStatus)}</td>
      <td>{verficarStatusByCompany(pagamento.transactionPaymentStatus)}</td>
      <td>{`R$ ${Number(pagamento.amountTicket).toFixed(2)}`}</td>
      <td>
        <button type="button">
          <Link to={`pagamentos/${pagamento.id}`}>
            <Image src={lupa} style={{ height: '30px' }} />
          </Link>
        </button>
      </td>
    </tr>
  );
}

export default function TabelaPagamentos({
  pagamentos,
}) {
  return (
    <>
      <Table
        striped
        bordered
        hover
        size="sm"
        id="tabela-pagamentos"
      >
        <thead>
          <tr>
            <th>
              <button
                type="button"
                id="btn-th"

              >
                Código
              </button>
            </th>
            <th>
              <button
                type="button"
                id="btn-th"
              >
                Data
              </button>
            </th>
            {/* <th>
              <button
                type="button"
                id="btn-th"
                onClick={() => {
                  setAscOrDesc();
                  setOrder('nome');
                }}
              >
                Nome
              </button>
            </th> */}
            <th>
              <button
                type="button"
                id="btn-th"
              >
                Status Negociação
              </button>
            </th>
            <th>
              <button
                type="button"
                id="btn-th"
              >
                Status Pagamento
              </button>
            </th>
            <th>
              <button
                type="button"
                id="btn-th"
              >
                Valor boleto
              </button>
            </th>
            <th>
              <button
                type="button"
                id="btn-th"
              >
                Detalhes
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {pagamentos.length <= 0 ? (
            <tr>
              <th className="sem-pag">
                <h3>Sem pagamentos</h3>
              </th>
            </tr>
          ) : (pagamentos.map((pagamento) => (
            <RowPayment key={pagamento.id} pagamento={pagamento} />
          )))}
        </tbody>
      </Table>

    </>
  );
}
