import React, { useEffect, useState } from 'react';
import {
  Form, Row, Col,
} from 'react-bootstrap';

import BoxFormDados from '../BoxFormDados';
import ModalError from '../ModalError';
import ModalConfirm from '../ModalConfirm';

import bancos from '../../utils/bancos.json';

import InputMask from '../InputMask';
import { treatStrings } from '../../utils/utils';

export default function BoxDadosBancarios({ dadosBancarios }) {
  const [agencia, setAgencia] = useState(dadosBancarios.agency);
  const [conta, setConta] = useState(dadosBancarios.number);
  const [natureza, setNatureza] = useState(dadosBancarios.bankingNature);
  const [cpfCnpjBanco, setCpfCnpjBanco] = useState(dadosBancarios.document || '');
  const [banco, setBanco] = useState(bancos.findIndex((b) => b.nome === dadosBancarios.bank));
  const [pix, setPix] = useState(dadosBancarios.pix);

  useEffect(() => {
    setNatureza(dadosBancarios.bankingNature);
    setCpfCnpjBanco(dadosBancarios.document || '');
    setAgencia(dadosBancarios.agency);
    setConta(dadosBancarios.account);
    setPix(dadosBancarios.pix);
    setBanco(bancos.findIndex((b) => b.nome === dadosBancarios.bank));
  }, [dadosBancarios]);

  const [modalError, setModalError] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  return (
    <BoxFormDados
      title="Dados bancário"
      editar={false}
      setEditar={() => null}
      // onSubmit={() => undefined}
      // onDelete={() => setModalConfirm(true)}
    >

      <ModalError show={modalError} close={() => setModalError(false)} msgErro="" />
      <ModalConfirm
        show={modalConfirm}
        mensagem="Deseja mesmo excluir esse dado bancário?"
        close={() => setModalConfirm(false)}
      />
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Agência</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Agência"
              value={agencia}
              onChange={(e) => setAgencia(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Número da conta</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Número da conta"
              value={conta}
              onChange={(e) => setConta(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Natureza</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Natureza"
              value={natureza}
              onChange={(e) => setNatureza(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Label>CPF/CNPJ</Form.Label>
            <InputMask
              disabled
              mask={treatStrings(cpfCnpjBanco).length > 11 ? '99.999.999/9999-99'
                : '999.999.999-99'}
              placeholder="CPF/CNPJ"
              value={cpfCnpjBanco}
              onChange={(e) => setCpfCnpjBanco(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Banco</Form.Label>
            <Form.Control
              required
              disabled
              value={banco}
              onChange={(e) => setBanco(e.target.value)}
              style={{ width: '100%', maxWidth: 'none' }}
              as="select"
              size="sm"
              id="select-bancos"
            >
              <option value="">Selecione</option>
              {bancos.map((b, index) => (
                <option key={b.num} value={index}>{`[${b.num}] - ${b.nome}`}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>PIX</Form.Label>
            <Form.Control
              disabled
              placeholder="PIX"
              value={pix}
              onChange={(e) => setPix(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
      </Row>
    </BoxFormDados>
  );
}
