import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import {
  FormSearch, ColTitle, HrTitle, FormGroupDate, RowDadosBancarios, ColValue,
} from './styles';
import './styles.css';
import api from '../../services/api';

import PageBase from '../../components/PageBase';
import DateInput from '../../components/DateInput';
import TabelaTransacoesEmpresas from '../../components/TabelaTransacoesEmpresas';
import ModalLoad from '../../components/ModalLoad';
import ModalConciliacao from '../../components/ModalConciliacao';
import loadingAni from '../../animations/loading.json';

export default function Componente() {
  const [dateFim, setDateFim] = useState(new Date());
  const now = new Date();
  const [dateIni, setDateIni] = useState(now.setDate(now.getDate() - 30));
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  // const [transactionType, setTransactionType] = useState();
  const [order] = useState('createdAt');
  const [ascOrDesc, setAscOrDesc] = useState('DESC');
  const [status, setStatus] = useState();
  // const [conciliada, setConciliada] = useState();

  const [idEmpresa, setIdEmpresa] = useState();
  const [empresa, setEmpresa] = useState();
  const [somaTotal, setSomaTotal] = useState(0);
  const [amountGainTotal, setAmountGainTotal] = useState(0);

  const [empresas, setEmpresas] = useState([]);
  const [empresaId, setEmpresaId] = useState();

  const [conciliar, setConciliar] = useState([]);
  const [pagamentosConciliar, setPagamentosConciliar] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);

  const [dadosBancarios, setDadosBancarios] = useState([]);

  const [totalConciliacao, setTotalConciliacao] = useState(0);

  const [temDadosBancarios, setTemDadosBancarios] = useState(false);

  const [showConciliacao, setShowConciliacao] = useState(false);

  const ordenacao = () => {
    if (ascOrDesc === 'ASC') {
      setAscOrDesc('DESC');
    } else {
      setAscOrDesc('ASC');
    }
  };

  const verificarDados = useCallback(async () => {
    setLoadingBank(true);
    const { data: company } = await api.get(`/companies/${idEmpresa}`);
    setEmpresa(company);

    if (company.dataBank) {
      setDadosBancarios(company.dataBank);
      setTemDadosBancarios(true);
    } else {
      setDadosBancarios([]);
      setTemDadosBancarios(false);
    }

    setLoadingBank(false);
  }, [idEmpresa]);

  useEffect(() => {
    (() => {
      const somaValores = (accumulator, currentValue) => accumulator + currentValue.valorLiberado;
      if (conciliar.length > 0) {
        const joinConciliations = [...conciliar];
        return setTotalConciliacao(joinConciliations.reduce(somaValores, 0));
      }
      return setTotalConciliacao(0);
    })();
  }, [conciliar, pagamentosConciliar]);

  async function loadTransactions() {
    setLoading(true);
    const iniDate = new Date(dateIni);
    const endDate = new Date(dateFim);

    const pagamentos = [];
    let transacoes = [];

    let response;

    const params = {
      startDate: `${iniDate.getFullYear()}-${
        iniDate.getMonth() + 1
      }-${iniDate.getDate() - 1}`,
      endDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
        endDate.getDate() + 2
      }`,
      order,
      ascOrDesc,
      // received: conciliada ? conciliada === 'success' : null,
      statusPayment: status === 0 || !status ? null : status,
      // transactionType: transactionType === 'Boletos' ? 'tickets' : transactionType,
    };

    if (empresaId) {
      response = await api.get(`/transactions/${empresaId}`, { params });
    } else {
      response = await api.get('/transactions', { params });
    }

    transacoes = response.data.transactions;
    let t = [...transacoes, ...pagamentos];

    const companiesIds = new Set();
    t.forEach((transaction) => {
      if (transaction.companyId) {
        companiesIds.add(transaction.companyId);
      }
    });

    const companiesResponse = await Promise.all(
      Array.from(companiesIds).map((id) => api.get(`/companies/${id}`)),
    );

    const companies = companiesResponse.map((company) => company.data);

    t = t.map((transaction) => {
      const company = companies.find((c) => c.id === transaction.companyId);

      const companyFantasyName = company.fantasyName;
      return { ...transaction, companyFantasyName };
    });

    t = t.sort((a, b) => {
      if (new Date(a.createdAt || a.created_at).getTime()
      > new Date(b.createdAt || b.created_at).getTime()) return -1;

      return 0;
    });

    if (t.length > 0) {
      if (t.length > 1) {
        const soma = t.reduce((previous, current) => {
          if (previous.amountTotal) {
            return previous.amountTotal + current.amountTotal;
          }

          if (current.amountTotal) return previous + current.amountTotal;

          return previous + current;
        });

        const sumAmountGainTotal = t.reduce((previous, current) => {
          const currentAmount = current.statusPayment !== 'success' ? 0 : current.amountGain;
          if (previous.amountGain) {
            const previousAmount = previous.statusPayment !== 'success' ? 0 : previous.amountGain;
            return previousAmount + currentAmount;
          }

          if (current.amountGain) return previous + currentAmount;

          return previous + current;
        });

        setAmountGainTotal(sumAmountGainTotal);

        setSomaTotal(soma);
      } else {
        setSomaTotal(t[0].total || t[0].amountTotal);
      }
    }

    setTransactions(t);
    setLoading(false);
  }

  useEffect(() => {
    loadTransactions();
  }, [page, order, ascOrDesc, dateIni, dateFim, status, empresaId]); // eslint-disable-line

  useEffect(() => {
    if (idEmpresa) verificarDados();
  }, [idEmpresa, verificarDados]);

  async function loadEmpresas() {
    const { data, status } = await api.get('/companies'); // eslint-disable-line
    if (status === 200) setEmpresas(data.companies);
  }

  useEffect(() => {
    loadEmpresas();
  }, []);

  return (
    <PageBase>
      <ModalConciliacao
        show={showConciliacao}
        dados={dadosBancarios}
        close={() => {
          window.location.reload();
        }}
        cancel={() => {
          setShowConciliacao(false);
        }}
        transacoes={conciliar}
        pagamentos={pagamentosConciliar}
        valor={Number(totalConciliacao)}
        empresa={empresa}
      />
      <ModalLoad show={loading} />
      <Row>
        <ColTitle>
          <Row>
            <h1>Transações</h1>
          </Row>
        </ColTitle>
      </Row>
      <Row>
        <Col>
          <HrTitle />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSearch
            onSubmit={(e) => {
              e.preventDefault();
              setPage(1);
              loadTransactions();
            }}
          >
            <Row>
              <Col md="auto">
                <FormGroupDate>
                  <Form.Label>Data Inicial</Form.Label>
                  <DateInput
                    selected={dateIni}
                    setSelected={setDateIni}
                  />
                </FormGroupDate>
              </Col>
              <Col md="auto">
                <FormGroupDate>
                  <Form.Label>Data Final</Form.Label>
                  <DateInput
                    label="Data Final"
                    selected={dateFim}
                    setSelected={setDateFim}
                  />
                </FormGroupDate>
              </Col>
              <Col md="auto">
                <Form.Group>
                  <Form.Label>Empresas</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={empresaId}
                    onChange={(e) => setEmpresaId(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {empresas.map((e) => (
                      <option value={e.id} key={e.id}>{e.socialReason}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              {/* <Col md="auto">
                <Form.Group>
                  <Form.Label>Tipo de Transação</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={transactionType}
                    onChange={(e) => setTransactionType(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value={false}>Vendas</option>
                    <option value="ticket">Boletos</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col md="auto">
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="waiting">aguardando</option>
                    <option value="success">pago</option>
                    <option value="denied">negado</option>
                    <option value="expired">expirado</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {/* <Col md="auto">
                <Form.Group>
                  <Form.Label>Concialiada</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={conciliada}
                    onChange={(e) => setConciliada(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="waiting">Pendente</option>
                    <option value="success">Conciliada</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
            </Row>
          </FormSearch>
        </Col>
      </Row>

      <RowDadosBancarios>
        <ColValue md="auto">
          <p>{`Valor total: R$ ${Number(totalConciliacao).toFixed(2)}`}</p>
        </ColValue>
        <Col>
          <Button
            variant="dark"
            size="sm"
            onClick={() => {
              if (conciliar.length > 0 || pagamentosConciliar.length > 0) {
                setShowConciliacao(true);
              }
            }}
            disabled={!temDadosBancarios || loadingBank}
          >
            {loadingBank ? (
              <Lottie
                options={{
                  animationData: loadingAni,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
                height={20}
              />
            ) : ('conciliar')}
          </Button>
        </Col>
      </RowDadosBancarios>

      <div className="amountTransactions">
        <p className="amountTransactions">
          {`Soma total das transações: R$ ${Number(somaTotal).toFixed(2).replace('.', ',')}`}
        </p>
        <p className="amountTransactions">|</p>
        <p className="amountTransactions">
          {`Valor total ganho: R$ ${Number(amountGainTotal).toFixed(2).replace('.', ',')}`}
        </p>
      </div>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>

      {transactions.length < 1 ? (
        <Row>
          <Col>
            <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Sem transações</h1>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <TabelaTransacoesEmpresas
                transactions={transactions}
                setAscOrDesc={ordenacao}
                setConciliar={setConciliar}
                setPagamentosConciliar={setPagamentosConciliar}
                pagamentosConciliar={pagamentosConciliar}
                conciliar={conciliar}
                setIdEmpresa={setIdEmpresa}
                idEmpresa={idEmpresa}
                isAll
              />
            </Col>
          </Row>
        </>
      )}
    </PageBase>
  );
}
