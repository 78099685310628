import React from 'react';
import { Table } from 'react-bootstrap';

import './styles.css';

import Transacao from '../Transacao/index';

function TabelaTransacoes({
  style, transactions, setConciliar, conciliar,
  isAll, setIdEmpresa, idEmpresa, setPagamentosConciliar, pagamentosConciliar,
}) {
  return (
    <Table
      className="table-transaction"
      striped
      bordered
      hover
      size="sm"
      style={style}
      responsive
    >
      <thead>
        <tr>

          <th>
            <button
              type="button"
            >
              Conciliar
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Código
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Tipo
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Data
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Status
            </button>
          </th>
          <th>
            <button
              type="button"
            >
              Conciliação
            </button>
          </th>

          {isAll && (
            <th>
              <button
                type="button"
              >
                Empresa
              </button>
            </th>
          )}

          <th>
            <button
              type="button"
            >
              Parcelas
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Valor total
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Valor Liberado
            </button>
          </th>

          <th>
            <button
              type="button"
            >
              Ganho
            </button>
          </th>
          {!isAll && (
            <>
              <th>
                <button
                  type="button"
                >
                  Parcelas
                </button>

              </th>

              <th>
                <button
                  type="button"
                >
                  Valor Parcela
                </button>
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, index) => (
          <Transacao
            transaction={transaction}
            key={index} // eslint-disable-line
            conciliar={conciliar}
            setConciliar={setConciliar}
            pagamentosConciliar={pagamentosConciliar}
            setPagamentosConciliar={setPagamentosConciliar}
            isAll={isAll}
            setIdEmpresa={setIdEmpresa}
            idEmpresa={idEmpresa}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default TabelaTransacoes;
