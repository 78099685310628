import React, { useState } from 'react';

import {
  Row, Col, Popover, OverlayTrigger,
} from 'react-bootstrap';

import formatedDate from '../../utils/formatedDate';
import definePagamento from '../../utils/define';

import {
  Box, ButtonPoppover,
} from './styles';

export default function Conciliacao({ conciliacao }) {
  const [transactions] = useState([...conciliacao.transactions]);
  const ButtonDadosBancarios = ({
    pix, agencia, conta, nomeBanco,
  }) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Dados Bancários</Popover.Title>
        <Popover.Content>
          <Row>
            <Col>
              Pix
            </Col>
          </Row>
          <Row>
            <Col>
              {`${pix}`}
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ margin: '5px' }} />
            </Col>
          </Row>
          <Row>
            <Col>
              Agência
            </Col>
          </Row>
          <Row>
            <Col>
              {`${agencia}`}
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ margin: '5px' }} />
            </Col>
          </Row>
          <Row>
            <Col>
              Conta
            </Col>
          </Row>
          <Row>
            <Col>
              {`${conta}`}
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ margin: '5px' }} />
            </Col>
          </Row>
          <Row>
            <Col>
              Instituição bancária
            </Col>
          </Row>
          <Row>
            <Col>
              {`${nomeBanco}`}
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger trigger="click" placement="right" overlay={popover}>
        <ButtonPoppover variant="dark" size="sm">Mostrar</ButtonPoppover>
      </OverlayTrigger>
    );
  };

  return (
    < >
      <tr>
        <td>
          <Box>
            {String(conciliacao.id).substring(0, 7)}
          </Box>
        </td>
        <td>
          <Box>
            {formatedDate(conciliacao.createdAt)}
          </Box>
        </td>
        <td>
          <Box>
            {`R$ ${Number(conciliacao.amountPaid).toFixed(2)}`}
          </Box>
        </td>
        <td>
          <Box>
            <ButtonDadosBancarios
              pix={conciliacao.dataBank.pix}
              agencia={conciliacao.dataBank.agency}
              conta={conciliacao.dataBank.account}
              nomeBanco={conciliacao.dataBank.bank}
            />
          </Box>
        </td>
        <td>
          <Box>
            {transactions.length > 0 ? transactions[0].code.substring(0, 10) : ''}
          </Box>
        </td>
        <td>
          <Box>
            {transactions.length > 0 ? definePagamento(transactions[0].transactionType) : ''}
          </Box>
        </td>
        <td>
          <Box>
            {transactions.length > 0 ? `R$ ${Number(transactions[0].amountReceived).toFixed(2)}` : ''}
          </Box>
        </td>
        <td>
          <Box>
            {transactions.length > 0 ? `R$ ${Number(transactions[0].amountTotal).toFixed(2)}` : ''}
          </Box>
        </td>
      </tr>
      {transactions.length > 0 ? (
        <>
          {transactions.map((t, i) => (i === 0 ? (
            <></>
          ) : (
            <tr key={t.id}>
              <td />
              <td />
              <td />
              <td />
              <td>
                <Box>
                  {`${t.code.substring(0, 10)}`}
                </Box>
              </td>
              <td>
                <Box>
                  {definePagamento(t.transactionType)}
                </Box>
              </td>
              <td>
                <Box>
                  {`R$ ${Number(t.amountReceived).toFixed(2)}`}
                </Box>
              </td>
              <td>
                <Box>
                  {`R$ ${Number(t.amountTotal).toFixed(2)}`}
                </Box>
              </td>
            </tr>
          )))}

        </>
      ) : (<></>)}
    </>
  );
}
