import React from 'react';

import FormHelp from './styles';

const FormHelpComponent = ({ children, onSubmit }) => (
  <FormHelp onSubmit={onSubmit}>
    {children}
  </FormHelp>
);

export default FormHelpComponent;
