/* eslint-disable */
// import api from './api';

export const getToken = () => {
  let cookies = decodeURIComponent(document.cookie);
  cookies = cookies.split(';');
  // console.log(cookie)
  for (const item of cookies) {
    let cookie = item;
    cookie = cookie.split('=');
    if (cookie[0].trim() === 'authToken') {
      cookie = cookie[1];
      return cookie;
    }
  }

  return undefined;
};

export const TOKEN_KEY = '';
export const isAuthenticated = () => getToken() !== undefined;
// export const isAuthenticated = async () => {
//   const token = getToken();
//   if(!token){
//     return false;
//   }
//   const {status } = await api.get(`/admin/token/${process.env.REACT_APP_HASH_ADMIN}`)
//   if(status === 401){
//     return false
//   }

//   return true
// }



export const login = (token) => {
  const date = new Date();
  date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
  document.cookie = `authToken=${token};expires=${date.toGMTString()}`;
};

export const logout = () => {
  const date = new Date();
  date.setTime(date.getTime() - (4 * 24 * 60 * 60 * 1000));
  return document.cookie = `authToken=; expires=${date.toGMTString()}`;
};
