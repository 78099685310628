import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Image, Form,
} from 'react-bootstrap';

import api from '../../services/api';

import TabelaPagamentos from '../../components/TabelaPagamentos';
import PageBase from '../../components/PageBase';
import DateInput from '../../components/DateInput';
import ModalLoad from '../../components/ModalLoad';

import './styles.css';

import search from '../../assets/search.svg';
import proximo from '../../assets/proximo.svg';
import devolta from '../../assets/devolta.svg';

export default function Home() {
  const [showLoad, setShowLoad] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  let data = new Date();
  const [dataIni, setDataIni] = useState(data.setDate(data.getDate() - 7));
  data = new Date();
  const [dataFim, setDataFim] = useState(data.setDate(data.getDate() + 1));
  // const [status, setStatus] = useState(undefined);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const linkRef = useRef();

  const loadPagamentos = async () => {
    const iniDate = new Date(dataIni);
    const endDate = new Date(dataFim);
    setShowLoad(true);
    const { status: httpStatus, data: objResponse } = await api.get('/tickets', {
      params: {
        startDate: `${iniDate.getFullYear()}-${
          iniDate.getMonth() + 1
        }-${iniDate.getDate() - 1}`,
        endDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
          endDate.getDate() + 2
        }`,
      },
    });

    if (httpStatus === 401) {
      return linkRef.current.click();
    }
    setPagamentos(objResponse.tickets);
    setShowLoad(false);
    return setPages(objResponse.pages);
  };

  useEffect(() => {
    loadPagamentos();
  }, []);

  const nextPage = useCallback(() => {
    if (page >= pages) {
      setPage(pages);
    } else {
      setPage(page + 1);
    }
  }, [page, pages]);

  const prevPage = useCallback(() => {
    if (page <= 1) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  }, [page]);

  return (
    <PageBase>
      <Link to="/" ref={linkRef} />
      <ModalLoad show={showLoad} />
      <Row>
        <Col>
          <h1>Pagamentos</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form
            id="form-pagamentos"
            onSubmit={(e) => {
              e.preventDefault();
              loadPagamentos();
            }}
          >
            <Row md={5}>
              <Col>
                <Form.Group style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Data Inicial</Form.Label>
                  <DateInput selected={dataIni} setSelected={setDataIni} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Data Final</Form.Label>
                  <DateInput selected={dataFim} setSelected={setDataFim} />
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="waiting">Aguardando</option>
                    <option value="success">Sucesso</option>
                    <option value="canceled">Cancelado</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group>
                  <button type="submit">
                    <Image src={search} />
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <TabelaPagamentos
            pagamentos={pagamentos}
            loadPagamentos={loadPagamentos}
          />
        </Col>
      </Row>
      <Row id={pagamentos.length > 0 ? 'row-on' : 'row-off'}>
        <button
          onClick={prevPage}
          id="pagination"
          type="button"
        >
          <Image src={devolta} style={{ height: '30px' }} />
        </button>

        <button
          onClick={nextPage}
          id="pagination"
          type="button"
        >
          <Image src={proximo} style={{ height: '30px' }} />
        </button>
      </Row>
    </PageBase>
  );
}
