import styled from 'styled-components';
import { Row, Image, Col } from 'react-bootstrap';

export const RowButtonAdd = styled(Row)`
  margin-top: 30px;

  h2{

  }

  .row{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  button{
    align-self: flex-end;
  }
`;

export const ColBox = styled(Col)`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
`;

export const DocumentoImg = styled(Image)`
  max-height: 200px;
  border-radius: 10px;

`;
