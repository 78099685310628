import React from 'react';
import { Modal } from 'react-bootstrap';

import Lottie from 'react-lottie';

import load from '../../animations/load.json';

export default function ModalLoad({ ...rest }) {
  return (
    <Modal
      {...rest}
      centered
    >
      <Modal.Body>
        <Lottie
          options={{
            animationData: load,
            autoplay: true,
            loop: true,
            rendererSettings: 'xMidYMid slice',
          }}
          height={200}
        />
      </Modal.Body>
    </Modal>
  );
}
