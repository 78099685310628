import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from '../../services/api';

import TabelaParceiros from '../../components/TabelaParceiros';
import PageBase from '../../components/PageBase';
import ModalLoad from '../../components/ModalLoad';

import './styles.css';

export default function Parceiros() {
  const [showLoad, setShowLoad] = useState(false);
  const [parceiros, setParceiros] = useState([]);

  const linkRef = useRef();

  async function loadParcerias() {
    setShowLoad(true);
    const response = await api.get('/parceiro');
    if (response.data.error) {
      return linkRef.current.click();
    }
    setParceiros(response.data);
    return setShowLoad(false);
  }
  useEffect(() => {
    loadParcerias();
  }, []);

  return (
    <PageBase>
      <ModalLoad show={showLoad} />
      <Row>
        <Col>
          <h1>Parceiros</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <TabelaParceiros parceiros={parceiros} loadParceiros={loadParcerias} />
        </Col>
      </Row>
    </PageBase>
  );
}
