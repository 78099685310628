import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import ModalFormUsuariosEmpresas from '../../components/ModalFormUsuariosEmpresas';
import api from '../../services/api';

import BoxDadosUsuario from '../../components/BoxDadosUsuario';
import ModalLoad from '../../components/ModalLoad';
import PageBase from '../../components/PageBase';
import { RowButtonAdd } from '../DadosEmpresa/styles';

export default function Componente({ match, location: { state } }) {
  const [idEmpresa] = useState(match.params.id);
  const [empresa] = useState(state.empresa);
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormUsuariosEmpresas, setShowFormUsuariosEmpresas] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const { data: usuariosResponse, status } = await api.get(`/users/companies/${idEmpresa}`);
    if (status === 200) {
      console.log('🚀 ~ file: index.js:23 ~ loadData ~ usuariosResponse:', usuariosResponse);
      setUsuarios(usuariosResponse.users);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageBase>
      <ModalLoad show={loading} />
      <ModalFormUsuariosEmpresas
        show={showFormUsuariosEmpresas}
        close={() => {
          setShowFormUsuariosEmpresas(false);
        }}
        cancel={() => setShowFormUsuariosEmpresas(false)}
        loadData={loadData}
        empresa={empresa}
        isAdmin={false}
      />
      <Row>
        <Col>
          <RowButtonAdd>
            <Col>
              <Row>
                <Col>
                  <h2>Usuários</h2>
                </Col>
                <Button
                  variant="success"
                  onClick={() => setShowFormUsuariosEmpresas(true)}
                >
                  Adicionar
                </Button>
              </Row>
              <hr style={{ margin: '5px' }} />
              {/* <Button
                size="sm"
                style={{ width: '150px' }}
                variant="secondary"
                onClick={() => history.push(`/empresas/${idEmpresa}/dados`, { empresa })}
              >
                Voltar
              </Button> */}
            </Col>
          </RowButtonAdd>

        </Col>
      </Row>
      {usuarios.length > 0 ? (
        <>
          {usuarios.map((usuario) => (
            <>
              {usuario && (
              <Row key={usuario.id} style={{ marginTop: '30px' }}>
                <BoxDadosUsuario
                  usuario={usuario}
                  empresa={empresa}
                  loadData={loadData}
                />
              </Row>
              ) }

            </>
          ))}
        </>
      ) : (
        <Row style={{ marginTop: '25px' }}>
          <h2 style={{ textAlign: 'center', width: '100%' }}>Sem usuários cadastrados</h2>
        </Row>
      )}
    </PageBase>
  );
}
