import styled from 'styled-components';
import { Col, Form } from 'react-bootstrap';

export const TextError = styled.p`
  color: red;
  font-weight: bold;
  height: 15px;
`;

export const ColError = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(Form.Label)`
  margin: 0;
`;
