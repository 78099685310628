import React, { memo } from 'react';

import { ElementScroll, ContainerContent } from './styles';

import Header from '../Header';
import Menu from '../Menu';

function PageBase({ children }) {
  return (
    <>
      <Header />
      <ElementScroll>
        <ContainerContent>
          {children}
        </ContainerContent>
      </ElementScroll>
      <Menu />
    </>
  );
}

export default memo(PageBase);
