import styled from 'styled-components';
import {
  Form, Col, Row,
} from 'react-bootstrap';

export const ColTitle = styled(Col)`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button{
    /* margin-top: 10px; */
    align-self: flex-end;
  }

  h1 {
    font-weight: bold;
  }
`;

export const ColWarning = styled(Col)`
  display: flex;
  margin-left: 30px;

  button {
    margin: 0 5px;
    height: 25px;
    display: flex;
    align-items: center;
  }

`;

export const ColValue = styled(Col)`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const FormSearch = styled(Form)`
  width: 90%;
  margin-bottom: 20px;

  label{
    margin: 0;
  }

  input, select{
    width: 200px;
    max-width: 200px;
  }

  button[type='submit']{
    border: none;
    background: none;
    outline: none;
  }
`;

export const HrTitle = styled.hr`
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const FormGroupDate = styled(Form.Group)`
  display: flex;
  flex-direction: column;
`;

export const RowDadosBancarios = styled(Row)`
  display: flex;

  h6 {
    font-size: 16px;
  }
  p, h6 {
    margin: 0;
    font-weight: bold;
  }

  button {
    width: 150px;
    margin-top: 15px;
  }
`;
