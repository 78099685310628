import React, { useRef, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './styles.css';

import pagamentos from '../../assets/pagamentos.svg';
import empresas from '../../assets/empresas.svg';
import cadastro from '../../assets/cadastro.svg';
import intercambio from '../../assets/intercambio.png';

export default function Menu() {
  const menuRef = useRef();

  useEffect(() => {
    menuRef.current.addEventListener('mouseenter', () => {
      menuRef.current.classList.add('hover');
    });
    menuRef.current.addEventListener('mouseleave', () => {
      menuRef.current.classList.remove('hover');
    });
  }, []);

  return (
    <nav className="menu-principal" ref={menuRef}>
      <div className="menu-option">
        <button type="button">
          <Link to="/transacoes">
            <Image src={intercambio} />
            <p>Transações</p>
          </Link>
        </button>
      </div>
      <div className="menu-option">
        <button type="button">
          <Link to="/pagamentos">
            <Image src={pagamentos} />
            <p>Pagamentos</p>
          </Link>
        </button>
      </div>
      <div className="menu-option">
        <button type="button">
          <Link to="/empresas">
            <Image src={empresas} />
            <p>Empresas</p>
          </Link>
        </button>
      </div>
      <div className="menu-option">
        <button type="button">
          <Link to="/cadastroempresas">
            <Image src={cadastro} id="cadastro-img" />
            <p>Cadastrar</p>
          </Link>
        </button>
      </div>
    </nav>
  );
}
