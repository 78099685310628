import React from 'react';
import { Table } from 'react-bootstrap';
import api from '../../services/api';

import './styles.css';

export default function TabelaParceiros({ parceiros, loadParceiros }) {
  function formatarData(value) {
    const date = new Date(value);

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }
  async function attStatus(event, parceiro) {
    await api.put(`/parceiro/${parceiro.id}`, { status: event.target.value });
    loadParceiros();
  }
  return (
    <Table
      striped
      hover
      size="sm"
      bordered
    >
      <thead>
        <tr>
          <th>Status</th>
          <th>Data e Hora</th>
          <th>Razão</th>
          <th>Telefone</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {parceiros.map((parceiro) => (
          <tr>
            <th>
              <select
                value={parceiro.status}
                onChange={(e) => attStatus(e, parceiro)}
                id="select-status"
              >
                <option value={1}>À negociar</option>
                <option value={2}>Negociando</option>
                <option value={3}>Sucesso</option>
                <option value={4}>Cancelado</option>
              </select>

            </th>
            <th>{formatarData(parceiro.createdAt)}</th>
            <th>{parceiro.razao}</th>
            <th>{parceiro.telefone}</th>
            <th>{parceiro.email}</th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
