import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';
import api from '../../services/api';
import validateCpfCnpj from '../../utils/validateCpfCnpj';

import { Box, RowBtnSubmit, TextError } from './styles';

import InputMask from '../../components/InputMask';
import FormHelp from '../../components/FormHelp';
import PageBase from '../../components/PageBase';

import bancos from '../../utils/bancos.json';
import cadLoad from '../../animations/cadLoading.json';

const ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
  'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

export default function DadosCadastrados() {
  const [person, setPerson] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razao, setRazao] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [segmento, setSegmento] = useState('');
  const [telPrincipal, setTelPrincipal] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState();
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [natureza, setNatureza] = useState('');
  const [cpfCnpjBanco, setCpfCnpjBanco] = useState('');
  const [holderType, setHolderType] = useState('');
  const [pix, setPix] = useState('');
  const [banco, setBanco] = useState('');

  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-99');
  const [tipoTabela, setTipoTabela] = useState();
  const [tablesTax, setTablesTax] = useState([]);

  const [msgErrorEmpresa, setMsgErrorEmpresa] = useState('');

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (holderType === 'f') setMaskCpfCnpj('999.999.999-99');
    else if (holderType === 'j') setMaskCpfCnpj('99.999.999/9999-99');
  }, [holderType]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoad(true);

    if (person === 'F' && !validateCpfCnpj(cpf)) {
      setLoad(false);
      setMsgErrorEmpresa('CPF inválido');
      return;
    }

    if (person === 'J' && !validateCpfCnpj(cnpj)) {
      setLoad(false);
      setMsgErrorEmpresa('Cnpj inválido');
      return;
    }

    if (!email.includes('@') || !email.includes('.com')) {
      setLoad(false);
      setMsgErrorEmpresa('Email inválido');
      return;
    }

    const { nome: nomeBanco } = bancos[banco];

    try {
      const { status } = await api.post('/companies', {
        type: person,
        document: person === 'F' ? cpf : cnpj,
        socialReason: razao,
        segment: segmento,
        fantasyName: nomeFantasia,
        phoneNumber: telPrincipal,
        email,
        rateTableId: tipoTabela,
        zipCode: cep,
        address: rua,
        number: numero,
        neighborhood: bairro,
        city: cidade,
        state: uf,
        agency: String(agencia),
        account: String(conta),
        bank: nomeBanco,
        accountType: natureza,
        dataBankDocument: cpfCnpjBanco,
        holderType,
        pix: String(pix),
      });

      if (status === 409) {
        setLoad(false);
        setMsgErrorEmpresa('Alguns dados podem já estar cadastrados (CNPJ, Razão Social, Numero de Telefone ou email');
        return;
      }

      if (status !== 201) {
        setLoad(false);
        setMsgErrorEmpresa('Houve um erro inesperado, tente novamente!');
        return;
      }

      setLoad(false);
      setMsgErrorEmpresa('');
      window.location.reload();
    } catch (error) {
      setLoad(false);
      setMsgErrorEmpresa('Houve um erro inesperado, tente novamente!');
    }
  }

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/table-rates');
      const filter = data.rateTables.filter((t) => String(t.type).toLowerCase() !== 'boletos');
      setTablesTax(filter);
    })();
  }, []);
  return (
    <PageBase>
      <Box>
        <Col>
          <FormHelp onSubmit={handleSubmit}>
            <Row>
              <Col>
                <h2>Cadastro de Empresa</h2>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Pessoa</Form.Label>
                  <Form.Control
                    required
                    value={person}
                    onChange={(e) => setPerson(e.target.value)}
                    as="select"
                    size="sm"
                  >
                    <option value="">Pessoa</option>
                    <option value="F">Pessoa Física</option>
                    <option value="J">Pessoa Jurídica</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {person === 'J' && (
              <Col>
                <Form.Group>
                  <Form.Label>CNPJ</Form.Label>
                  <InputMask
                    placeholder="CNPJ da empresa"
                    mask="99.999.999/9999-99"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              )}
              {person === 'F' && (
                <Col>
                  <Form.Group>
                    <Form.Label>CPF</Form.Label>
                    <InputMask
                      placeholder="CPF"
                      mask="999.999.999-99"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              )}

              <Col>
                <Form.Group>
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control
                    placeholder="Razão Social"
                    required
                    value={razao}
                    onChange={(e) => setRazao(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nome Fantasia</Form.Label>
                  <Form.Control
                    placeholder="Nome Fantasia"
                    required
                    value={nomeFantasia}
                    onChange={(e) => setNomeFantasia(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Segmento</Form.Label>
                  <Form.Control
                    placeholder="segmento"
                    required
                    value={segmento}
                    onChange={(e) => setSegmento(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Rua</Form.Label>
                  <Form.Control
                    placeholder="Rua"
                    required
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    placeholder="Número"
                    required
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    size="sm"
                    type="number"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>CEP</Form.Label>
                  <InputMask
                    placeholder="CEP"
                    mask="99999-999"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    placeholder="Bairro"
                    required
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    placeholder="Cidade"
                    required
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>UF</Form.Label>
                  <Form.Control
                    required
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    as="select"
                    size="sm"
                  >
                    <option value="">UF</option>
                    {ufs.map((u) => (
                      <option key={u} value={u}>{u}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Telefone 1</Form.Label>
                  <InputMask
                    placeholder="Telefone 1"
                    mask="(99) 9 9999-9999"
                    value={telPrincipal}
                    onChange={(e) => setTelPrincipal(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Tabela de taxas</Form.Label>
                  <Form.Control
                    required
                    value={tipoTabela}
                    onChange={(e) => {
                      setTipoTabela(e.target.value);
                    }}
                    size="sm"
                    as="select"
                  >
                    <option value="">Selecione</option>
                    {tablesTax.map((t) => (
                      <option
                        key={t.id}
                        value={t.id}
                      >
                        {t.type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    required
                    placeholder="Agência"
                    value={agencia}
                    onChange={(e) => setAgencia(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Número da conta</Form.Label>
                  <Form.Control
                    required
                    placeholder="Número da conta"
                    value={conta}
                    onChange={(e) => setConta(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Natureza</Form.Label>
                  <Form.Control
                    required
                    placeholder="Natureza"
                    value={natureza}
                    onChange={(e) => setNatureza(e.target.value)}
                    size="sm"
                    as="select"
                  >
                    <option value="">Selecione</option>
                    <option value="checking account">Conta corrente</option>
                    <option value="savings account">Conta Poupança</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CPF/CNPJ</Form.Label>
                  <Form.Control
                    required
                    value={holderType}
                    onChange={(e) => setHolderType(e.target.value)}
                    style={{ width: '100%', maxWidth: 'none' }}
                    as="select"
                    size="sm"
                    id="select-bancos"
                  >
                    <option value="">Selecione</option>
                    <option value="f">CPF</option>
                    <option value="j">CNPJ</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CPF/CNPJ</Form.Label>
                  <InputMask
                    mask={maskCpfCnpj}
                    placeholder="CPF/CNPJ"
                    value={cpfCnpjBanco}
                    onChange={(e) => setCpfCnpjBanco(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Banco</Form.Label>
                  <Form.Control
                    required
                    value={banco}
                    onChange={(e) => setBanco(e.target.value)}
                    style={{ width: '100%', maxWidth: 'none' }}
                    as="select"
                    size="sm"
                    id="select-bancos"
                  >
                    <option value="">Selecione</option>
                    {bancos.map((b, index) => (
                      <option key={b.num} value={index}>{`[${b.num}] - ${b.nome}`}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>PIX</Form.Label>
                  <Form.Control
                    required
                    placeholder="PIX"
                    value={pix}
                    onChange={(e) => setPix(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <RowBtnSubmit>
              <TextError>{msgErrorEmpresa}</TextError>
              <Button type="submit" disabled={load} size="sm">
                {load ? (
                  <Lottie
                    options={{
                      animationData: cadLoad,
                      autoplay: true,
                      loop: true,
                      rendererSettings: 'xMidYMid slice',
                    }}
                    height={25}
                    width={80}
                  />
                ) : ('Cadastrar')}
              </Button>
            </RowBtnSubmit>
          </FormHelp>
        </Col>

      </Box>
      {/* <Box>
        <Col>
          <h2>Documentos</h2>
          <hr />
          <FormHelp onSubmit={handleSubmitDocumentos}>
            <Row>
              <Col>
                <Form.Group style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <h5>Comprovante Bancario</h5>
                  <Form.File
                    accept="image/png, image/jpg, application/pdf"
                    onChange={(e) => setComprovanteBancario(e.target.files[0])}
                    size="sm"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <h5>Contrato Social</h5>
                  <Form.File
                    accept="image/png, image/jpg, application/pdf"
                    onChange={(e) => setContratoSocial(e.target.files[0])}
                    size="sm"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <h5>Comprovante de Endereço</h5>
                  <Form.File
                    accept="image/png, image/jpg, application/pdf"
                    onChange={(e) => setComprovanteEndereco(e.target.files[0])}
                    size="sm"
                    required
                  />
                </Form.Group>

              </Col>
            </Row>
            {!idEmpresa ? (<></>) : (
              <RowBtnSubmit>
                <TextError>{msgErrorDocumentos}</TextError>
                <Button type="submit" disabled={load} size="sm">
                  {load ? (
                    <Lottie
                      options={{
                        animationData: cadLoad,
                        autoplay: true,
                        loop: true,
                        rendererSettings: 'xMidYMid slice',
                      }}
                      height={25}
                      width={80}
                    />
                  ) : ('Cadastrar')}
                </Button>
              </RowBtnSubmit>
            )}
          </FormHelp>
        </Col>
      </Box> */}
      {/* <Box>
        <Col>
          <h2>Dados Bancários</h2>
          <hr />
          <FormHelp onSubmit={handleSubmitDadosBancarios}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    required
                    placeholder="Agência"
                    value={agencia}
                    onChange={(e) => setAgencia(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Número da conta</Form.Label>
                  <Form.Control
                    required
                    placeholder="Número da conta"
                    value={conta}
                    onChange={(e) => setConta(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Natureza</Form.Label>
                  <Form.Control
                    required
                    placeholder="Natureza"
                    value={natureza}
                    onChange={(e) => setNatureza(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>CPF/CNPJ</Form.Label>
                  <InputMask
                    mask="999.999.999-99"
                    placeholder="CPF/CNPJ"
                    value={cpfCnpjBanco}
                    onChange={(e) => setCpfCnpjBanco(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Banco</Form.Label>
                  <Form.Control
                    required
                    value={banco}
                    onChange={(e) => setBanco(e.target.value)}
                    style={{ width: '100%', maxWidth: 'none' }}
                    as="select"
                    size="sm"
                    id="select-bancos"
                  >
                    <option value="">Selecione</option>
                    {bancos.map((b, index) => (
                      <option key={b.num} value={index}>{`[${b.num}] - ${b.nome}`}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {!idEmpresa ? (<></>) : (
              <RowBtnSubmit>
                <TextError>{msgErrorDadosBancarios}</TextError>
                <Button type="submit" disabled={load} size="sm">
                  {load ? (
                    <Lottie
                      options={{
                        animationData: cadLoad,
                        autoplay: true,
                        loop: true,
                        rendererSettings: 'xMidYMid slice',
                      }}
                      height={25}
                      width={80}
                    />
                  ) : ('Cadastrar')}
                </Button>
              </RowBtnSubmit>
            )}
          </FormHelp>
        </Col>
      </Box> */}
    </PageBase>
  );
}
