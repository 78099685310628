import React, { useState } from 'react';
import {
  Form, Row, Col,
} from 'react-bootstrap';
import api from '../../services/api';

import BoxFormDados from '../BoxFormDados';
import ModalLoad from '../ModalLoad';
import ModalError from '../ModalError';
import ModalConfirm from '../ModalConfirm';

export default function BoxDadosUsuarios({ empresa, usuario, loadData }) {
  const [idUsuario] = useState(usuario.id);
  const [nome, setNome] = useState(usuario.name);
  const [email, setEmail] = useState(usuario.email);
  const [username, setUsername] = useState(usuario.username);
  const [admin, setAdmin] = useState(usuario.isAdmin);

  const [load, setLoad] = useState(false);
  const [editar, setEditar] = useState(false); // eslint-disable-line
  const [modalError, setModalError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [modalConfirm, setModalConfirm] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    if (username.trim().includes(' ')) {
      setLoad(false);
      setModalError(true);
      return setMsgError('Nome de usuário não pode conter espaços');
    }
    setLoad(true);
    if (!email.includes('@') || !email.includes('.com')) {
      setLoad(false);
      setModalError(true);
      return setMsgError('Email inválido');
    }

    // if (password !== confirmPassword) {
    //   setLoad(false);
    //   setModalError(true);
    //   return setMsgError('Senhas não são iguais');
    // }

    setMsgError('');

    try {
      const { status } = await api.put(`/empresas/${empresa.id}/users/${idUsuario}`, {
        nome,
        email,
        username,
        admin: true,
      });

      if (status !== 202) {
        setLoad(false);
        setModalError(true);
        return setMsgError('Houve um erro inesperado, tente novamente!');
      }
      setEditar(false);
      return setLoad(false);
    } catch (err) {
      setLoad(false);
      setModalError(true);
      return setMsgError('Houve um erro inesperado, tente novamente!');
    }
  }

  async function deleteData() {
    await api.delete(`/empresas/${empresa.id}/users/${idUsuario}`);
    loadData();
  }

  return (
    <BoxFormDados
      title="Usuario"
      editar={false}
      setEditar={setEditar}
      // onSubmit={handleSubmit}
      // onDelete={() => setModalConfirm(true)}
    >
      <ModalLoad show={load} />
      <ModalError show={modalError} close={() => setModalError(false)} msgErro={msgError} />
      <ModalConfirm
        show={modalConfirm}
        mensagem="Deseja mesmo excluir esse usuário?"
        close={() => setModalConfirm(false)}
        onDelete={deleteData}
      />
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Nome Completo"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label>Nome de Usuário</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Nome de Usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              disabled
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Administrativo</Form.Label>
            <Form.Control
              required
              disabled
              as="select"
              size="sm"
              value={admin}
              onChange={(e) => setAdmin(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value={false}>Não</option>
              <option value>Sim</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Form.Group>
            <Form.Label>Data de Criação</Form.Label>
            <Form.Control
              disabled
              size="sm"
              value={formatedDate(usuario.createdAt)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Última atualização</Form.Label>
            <Form.Control
              disabled
              size="sm"
              value={formatedDate(usuario.updatedAt)}
            />
          </Form.Group>
        </Col>
      </Row> */}
      {/* TODO: PEnsar numa maneira de mudança de senha */}
      {/* <Row>
        <Col>
          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              required
              disabled
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Confirme a Senha</Form.Label>
            <Form.Control
              placeholder="Confirme a senha"
              type="password"
              required
              disabled
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Col>
      </Row> */}
    </BoxFormDados>
  );
}
