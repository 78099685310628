import React, { useEffect, useState } from 'react';
import {
  Row, Col, Form,
} from 'react-bootstrap';
import api from '../../services/api';

import BoxFormDados from '../BoxFormDados';
import InputMask from '../InputMask';
import ModalLoad from '../ModalLoad';
import ModalError from '../ModalError';

const ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
  'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

export default function BoxDataEmpresa({ empresa }) {
  const [idEmpresa, setIdEmpresa] = useState(empresa.id);

  const [razao, setRazao] = useState(empresa.socialReason);
  const [nomeFantasia, setNomeFantasia] = useState(empresa.fantasyName);
  const [segmento, setSegmento] = useState(empresa.segment);
  const [cnpj, setCnpj] = useState(empresa.document);
  const [cpf, setCpf] = useState(empresa.document);
  const [rua, setRua] = useState(empresa.address ? empresa.address.address : undefined);
  const [numero, setNumero] = useState(empresa.address ? empresa.address.number : undefined);
  const [cep, setCep] = useState(empresa.address ? empresa.address.cep : undefined);
  const [bairro, setBairro] = useState(empresa.address
    ? empresa.address.neighborhood : undefined);
  const [cidade, setCidade] = useState(empresa.address ? empresa.address.city : undefined);
  const [email, setEmail] = useState(empresa.email);
  const [uf, setUf] = useState(empresa.address ? empresa.address.state : undefined);
  const [telPrincipal, setTelPrincipal] = useState(empresa.phoneNumber);
  const [tipoTabela, setTipoTabela] = useState(empresa.rateTable);
  const [tablesTax, setTablesTax] = useState([]);

  const [editar, setEditar] = useState(false);
  const [load, setLoad] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [msgError, setMsgError] = useState('Houve um erro inesperado, tente novamente!');

  useEffect(() => {
    setIdEmpresa(empresa.id);
    setRazao(empresa.socialReason);
    setNomeFantasia(empresa.fantasyName);
    setSegmento(empresa.segment);
    setCnpj(empresa.document);
    setCpf(empresa.document);
    setRua(empresa.address ? empresa.address.address : undefined);
    setNumero(empresa.address ? empresa.address.number : undefined);
    setCep(empresa.address ? empresa.address.zipCode : undefined);
    setBairro(empresa.address ? empresa.address.neighborhood : undefined);
    setCidade(empresa.address ? empresa.address.city : undefined);
    setEmail(empresa.email);
    setUf(empresa.address ? empresa.address.state : undefined);
    setTelPrincipal(empresa.phoneNumber);
    setTipoTabela(empresa.rateTableId);
  }, [empresa]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/table-rates');
      const filter = data.rateTables.filter((t) => String(t.type).toLowerCase() !== 'boletos');
      setTablesTax(filter);
    })();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoad(true);

    if (!email.includes('@') || !email.includes('.com')) {
      setLoad(false);
      setModalError(true);
      return setMsgError('Email inválido');
    }

    try {
      const { status } = await api.put(`/companies/${idEmpresa}`, {
        rateTableId: tipoTabela,
        phoneNumber: telPrincipal,
        email,
        address: rua,
        zipCode: cep,
        city: cidade,
        neighborhood: bairro,
        number: numero,
        state: uf,
      });
      if (status !== 202) {
        setLoad(false);
        setModalError(true);
        return setMsgError('Houve um erro inesperado, tente novamente!');
      }
      setEditar(false);
      return setLoad(false);
    } catch (error) {
      setLoad(false);
      setModalError(true);
      return setMsgError('Houve um erro inesperado, tente novamente!');
    }
  }

  return (
    <BoxFormDados
      title="empresa"
      editar={editar}
      setEditar={setEditar}
      onSubmit={handleSubmit}
    >
      <ModalLoad show={load} />
      <ModalError show={modalError} close={() => setModalError(false)} msgErro={msgError} />
      <Row>
        {String(empresa.personType).toUpperCase() === 'J' && (

        <Col>
          <Form.Group>
            <Form.Label>CNPJ</Form.Label>
            <InputMask
              mask="99.999.999/9999-99"
              size="sm"
              value={cnpj}
              disabled
              onChange={(e) => setCnpj(e.target.value)}
            />
          </Form.Group>
        </Col>

        )}

        {String(empresa.personType) === 'F' && (
        <Col>
          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <InputMask
              mask="999.999.999-99"
              size="sm"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              disabled
            />
          </Form.Group>
        </Col>
        )}

        <Col>
          <Form.Group>
            <Form.Label>Nome Fantasia</Form.Label>
            <Form.Control
              required
              size="sm"
              value={nomeFantasia}
              onChange={(e) => setNomeFantasia(e.target.value)}
              disabled
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              required
              size="sm"
              value={razao}
              onChange={(e) => setRazao(e.target.value)}
              disabled
            />
          </Form.Group>
        </Col>

      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Segmento</Form.Label>
            <Form.Control
              required
              size="sm"
              value={segmento}
              onChange={(e) => setSegmento(e.target.value)}
              disabled
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              required
              size="sm"
              value={rua}
              disabled={!editar}
              onChange={(e) => setRua(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Número</Form.Label>
            <Form.Control
              required
              size="sm"
              type="number"
              value={numero}
              disabled={!editar}
              onChange={(e) => setNumero(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>CEP</Form.Label>
            <InputMask
              mask="99999-999"
              size="sm"
              value={cep}
              disabled={!editar}
              onChange={(e) => setCep(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              required
              size="sm"
              value={bairro}
              disabled={!editar}
              onChange={(e) => setBairro(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              required
              size="sm"
              value={cidade}
              disabled={!editar}
              onChange={(e) => setCidade(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>UF</Form.Label>
            <Form.Control
              disabled={!editar}
              required
              value={uf}
              onChange={(e) => setUf(e.target.value)}
              as="select"
              size="sm"
            >
              <option value="">UF</option>
              {ufs.map((u) => (
                <option key={u} value={u}>{u}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Telefone 1</Form.Label>
            <InputMask
              mask="(99) 99999-9999"
              size="sm"
              value={telPrincipal}
              disabled={!editar}
              onChange={(e) => setTelPrincipal(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              size="sm"
              value={email}
              disabled={!editar}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Tabela de taxas</Form.Label>
            <Form.Control
              required
              value={tipoTabela}
              disabled={!editar}
              onChange={(e) => setTipoTabela(e.target.value)}
              size="sm"
              as="select"
            >
              <option value="">Selecione</option>
              {tablesTax.map((t) => (
                <option
                  key={t.id}
                  value={t.id}
                >
                  {t.type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <Form.Group>
            <Form.Label>Data de Afiliação</Form.Label>
            <Form.Control
              required
              size="sm"
              value={createdAt}
              disabled
            />
          </Form.Group>
        </Col> */}
        <Col>
          {/* <Form.Group>
            <Form.Label>Última atualização de dados</Form.Label>
            <Form.Control
              required
              size="sm"
              value={updatedAt}
              disabled
            />
          </Form.Group> */}
        </Col>
      </Row>
    </BoxFormDados>
  );
}
