import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import api from '../../services/api';
import { login } from '../../services/auth';

import './styles.css';

import predios from '../../assets/predios.png';

import logo from '../../assets/logo.svg';
import user from '../../assets/user.svg';
import cadeado from '../../assets/cadeado.svg';

import loadingAni from '../../animations/loading.json';

function Login({ history }) {
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [erroMessage, setErroMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const linkRef = useRef();

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    if (!usuario) {
      setErroMessage('Insira seu usuário');
      setLoading(false);
      return;
    }
    if (!password) {
      setErroMessage('Insira sua senha');
      setLoading(false);
      return;
    }

    setErroMessage('');

    try {
      const response = await api.post('/session', { usernameOrEmail: usuario, password });
      if (response.status === 401 || response.status === 404) {
        setErroMessage('Usuário ou senha inválidos');
        setLoading(false);
        return;
      }
      login(response.data.token);

      localStorage.setItem('userlogged', response.data.username);
      setLoading(false);
      history.push('/transacoes');
    } catch (error) {
      setErroMessage('Problemas no servidor');
      setLoading(false);
    }
  }
  return (
    <div className="container-login">
      <Link to="/transacoes" ref={linkRef} />
      <div className="column-logo">
        <img src={predios} alt="Predios" id="image-predios" />
        <img src={logo} alt="HelpManey" id="logo" />
        <div className="helpmaney">
          <h2 id="help">Help</h2>
          <h2 id="maney">Maney</h2>
        </div>
        <h2 id="empresas">Admin</h2>
      </div>
      <div className="column-form">
        <h1>Bem vindo!</h1>
        <div className="acesse">
          <hr />
          <h4>acesse com seu usuário e senha</h4>
          <hr />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <img src={user} alt="Username" />
            <input type="text" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
          </div>
          <div className="input-group">
            <img src={cadeado} alt="Password" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button type="submit">
            {loading ? (
              <Lottie
                options={{
                  animationData: loadingAni,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
              />
            ) : 'Entrar'}
          </button>
          <p>{erroMessage}</p>
        </form>
      </div>
    </div>
  );
}

export default Login;
