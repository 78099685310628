import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function ModalLoad({ msgErro, close, ...rest }) {
  return (
    <Modal
      {...rest}
      centered
    >
      <Modal.Header>
        <h3>Erro!!</h3>
      </Modal.Header>
      <Modal.Body style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <p>{msgErro}</p>
        <Button
          style={{
            width: '200px',
          }}
          variant="danger"
          onClick={close}
        >
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
}
