import React, { useState } from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';

import BoxDadosEmpresa from '../../components/BoxDadosEmpresa';
import PageBase from '../../components/PageBase';
import { useFetchCompanyById } from '../../hooks/useFetchCompanies';

export default function DadosEmpresa({ match, history }) {
  const [idEmpresa] = useState(match.params.id);

  const { company: empresa } = useFetchCompanyById({ id: idEmpresa });

  return (
    <PageBase>
      <Row>
        <Col>
          <Row>
            <Col>
              <h1>{empresa.socialReason}</h1>
              <h3>{`Código empresa: ${idEmpresa}`}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ margin: '5px' }} />
            </Col>
          </Row>
          <Row>
            <Col />
          </Row>
          <Row>
            <Col md="auto">
              <Button
                size="sm"
                variant="info"
                onClick={() => history.push(`/empresas/${idEmpresa}/dados/bancarios`, { empresa })}
              >
                Dados Bancários
              </Button>
            </Col>
            <Col md="auto">
              <Button
                size="sm"
                variant="info"
                onClick={() => history.push(`/empresas/${idEmpresa}/dados/usuarios`, { empresa })}
              >
                Usuários
              </Button>
            </Col>
            <Col md="auto">
              <Button
                size="sm"
                variant="info"
                onClick={() => history.push(`/empresas/${idEmpresa}/dados/conciliacoes`, { empresa })}
              >
                Conciliações bancárias
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <BoxDadosEmpresa empresa={empresa} />
      </Row>
    </PageBase>
  );
}
