import React, { useState } from 'react';
import Lottie from 'react-lottie';
import {
  Form, Row, Col, Modal, Button,
} from 'react-bootstrap';
import { TextError, ColError } from './styles';
import api from '../../services/api';

import FormHelp from '../FormHelp';

import cadLoading from '../../animations/cadLoading.json';

export default function ModalFormUsuariosEmpresas({
  isAdmin, cancel, loadData, empresa, close, ...rest
}) {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [admin, setAdmin] = useState(null);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    if (username.trim().includes(' ')) {
      setLoading(false);
      return setError('Nome de usuário não pode conter espaços');
    }
    setLoading(true);
    if (!email.includes('@') || !email.includes('.com')) {
      setLoading(false);
      return setError('Email inválido');
    }

    if (isAdmin && admin === 'false') {
      setLoading(false);
      return setError('Essa empresa está sem usuário administrativo!');
    }

    if (password !== confirmPassword) {
      setLoading(false);
      return setError('Senhas não são iguais');
    }

    setError('');

    try {
      const { status } = await api.post('/users', {
        companyId: empresa.id,
        name: nome,
        email,
        username,
        password,
        confirmPassword,
        isAdmin: true,
      });

      if (status === 409) {
        setLoading(false);
        return setError('Nome de usuário ou email já cadastrado!');
      }
      if (status !== 201) {
        setLoading(false);
        return setError('Houve um erro inesperado, tente novamente!');
      }

      setLoading(false);
      close();
      return loadData();
    } catch (err) {
      setLoading(false);
      return setError('Houve um erro inesperado, tente novamente!');
    }
  }
  return (
    <Modal
      {...rest}
      centered
      size="lg"
    >
      <FormHelp onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Usuários ${empresa.razao}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  required
                  placeholder="Nome Completo"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Nome de Usuário</Form.Label>
                <Form.Control
                  required
                  placeholder="Nome de Usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group>
                <Form.Label>Administrativo</Form.Label>
                <Form.Control
                  required
                  as="select"
                  size="sm"
                  value={admin}
                  onChange={(e) => setAdmin(e.target.value)}
                >
                  <option value="">Selecione</option>
                  <option value={false}>Não</option>
                  <option value>Sim</option>
                </Form.Control>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Confirme a Senha</Form.Label>
                <Form.Control
                  placeholder="Confirme a senha"
                  type="password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <ColError>
              <TextError>{error}</TextError>
            </ColError>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setNome('');
              setUsername('');
              setEmail('');
              setAdmin('');
              setPassword('');
              setConfirmPassword('');
              setError('');
              cancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="info"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Lottie
                options={{
                  animationData: cadLoading,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
                height={25}
                width={80}
              />
            ) : ('Cadastrar')}

          </Button>
        </Modal.Footer>
      </FormHelp>
    </Modal>
  );
}
