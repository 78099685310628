import React, { useState, useEffect } from 'react';
import {
  Row, Col, Table, Form, Image,
} from 'react-bootstrap';
import api from '../../services/api';

import ModalLoad from '../../components/ModalLoad';
import PageBase from '../../components/PageBase';
import DateInput from '../../components/DateInput';
// import FormHelp from '../../components/FormHelp';

import { ButtonTh, FormSearch, FormGroupDate } from './styles';

import search from '../../assets/search.svg';
import Conciliacao from './Conciliacao';

export default function Componente({ match }) {
  const [idEmpresa] = useState(match.params.id);
  const [loading, setLoading] = useState(false);
  const [conciliacoes, setConciliacoes] = useState([]);
  const [order] = useState('createdAt');
  const [ascOrDesc] = useState('DESC');
  const now = new Date();
  const [dateFim, setDateFim] = useState(new Date());
  const [dateIni, setDateIni] = useState(new Date(now.setDate(now.getDate() - 30)));

  const loadData = async () => {
    setLoading(true);
    const { data, status } = await api.get(`/receipts/companies/${idEmpresa}`, {
      params: {
        startDate: `${dateIni.getFullYear()}-${
          dateIni.getMonth() + 1
        }-${dateIni.getDate() - 1}`,
        endDate: `${dateFim.getFullYear()}-${dateFim.getMonth() + 1}-${
          dateFim.getDate() + 2
        }`,
      },
    });

    if (status === 200) {
      setConciliacoes(data.receipts);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [order, ascOrDesc]); // eslint-disable-line

  return (
    <PageBase>
      <ModalLoad show={loading} />
      <Row>
        <Col>
          <Row>
            <h1>Conciliações Bancárias</h1>
          </Row>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSearch
            onSubmit={(e) => {
              e.preventDefault();
              loadData();
            }}
          >
            <Row>
              <Col md="auto">
                <FormGroupDate>
                  <Form.Label>Data Inicial</Form.Label>
                  <DateInput
                    selected={dateIni}
                    setSelected={setDateIni}
                  />
                </FormGroupDate>
              </Col>
              <Col md="auto">
                <FormGroupDate>
                  <Form.Label>Data Final</Form.Label>
                  <DateInput
                    label="Data Final"
                    selected={dateFim}
                    setSelected={setDateFim}
                  />
                </FormGroupDate>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <button
                  type="submit"
                >
                  <Image src={search} />
                </button>
              </Col>
            </Row>
          </FormSearch>
        </Col>
      </Row>
      {conciliacoes.length > 0 ? (
        <>
          <Table
            striped
            size="sm"
            hover
            bordered
          >
            <thead>
              <tr>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Código
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Data
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Valor Pago
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Dados bancários
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Código
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Tipo
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Valor Liberado
                  </ButtonTh>
                </th>
                <th style={{ padding: 0 }}>
                  <ButtonTh
                    type="button"
                  >
                    Valor Transacao
                  </ButtonTh>
                </th>
              </tr>
            </thead>
            <tbody>
              {conciliacoes.map((c) => (
                <Conciliacao conciliacao={c} key={c} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (<></>)}
    </PageBase>
  );
}
