import styled from 'styled-components';
import { Col, Button, Form } from 'react-bootstrap';

export const Box = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonPoppover = styled(Button)`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonTh = styled.button`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  background-color: var(--cor-escura);
  color: #fff;

  &:hover{
    background: #0F7444;
  }
`;

export const FormSearch = styled(Form)`
  width: 90%;
  margin-bottom: 20px;

  label{
    margin: 0;
  }

  input, select{
    width: 200px;
    max-width: 200px;
  }

  button[type='submit']{
    border: none;
    background: none;
    outline: none;
  }
`;

export const HrTitle = styled.hr`
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const FormGroupDate = styled(Form.Group)`
  display: flex;
  flex-direction: column;
`;
