import React from 'react';
import { Form } from 'react-bootstrap';
import MaskedInput from 'react-input-mask';

// import './styles.css';

export default function InputMask({
  mask, value, onChange, placeholder, disabled, size, maskChar = '_',
}) {
  return (
    <MaskedInput
      mask={mask}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      maskChar={maskChar}
      disabled={disabled}
    >
      {() => <Form.Control required placeholder={placeholder} disabled={disabled} size="sm" />}
    </MaskedInput>
  );
}
