import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';

import formatedDate from '../../utils/formatedDate';
import definirFormaPagamento from '../../utils/define';

import verificado from '../../assets/verificado.svg';
import recarregar from '../../assets/recarregar.svg';
import stop from '../../assets/no-stopping.png';
import warning from '../../assets/warning.png';

function defineStatus(status) {
  switch (status) {
    case 'waiting':
      return 'aguardando';
    case 'success':
      return 'pago';
    case 'denied':
      return 'negado';
    case 'expired':
      return 'expirado';
    default:
      return 'nada';
  }
}

function defineIconConciliation(transaction) {
  if (transaction.type === 'ticket') {
    return warning;
  }
  if (transaction.statusPayment === 'success'
     && transaction.statusReceipt === 'success') return verificado;

  if (transaction.statusPayment === 'success'
     && transaction.statusReceipt === 'waiting') return recarregar;

  return stop;
}

export default function TabelaTransacoes({
  transaction, setConciliar, conciliar, isAll,
  setIdEmpresa, idEmpresa, pagamentosConciliar,
}) {
  const [checked, setChecked] = useState(false);

  const addConciliar = (trans) => {
    setConciliar([trans, ...conciliar]);
  };

  const removeConciliar = (trans) => {
    const newArray = conciliar.filter((t) => t.id !== trans.id);

    setConciliar(newArray);
  };

  useEffect(() => {
    if (checked) {
      addConciliar({
        id: transaction.id,
        valorLiberado: transaction.amountReceivable,
        valorTransacao: transaction.amountTotal,
        idEmpresa: transaction.companyId,
      });
    } else {
      removeConciliar(transaction);
    }
  }, [checked]); // eslint-disable-line

  useEffect(() => {
    if (isAll) {
      if (conciliar.length > 0) {
        setIdEmpresa(conciliar[0].idEmpresa);
      // } else if (pagamentosConciliar.length > 0) {
      //   setIdEmpresa(pagamentosConciliar[0].idEmpresa);
      } else {
        setIdEmpresa(null);
      }
    }
  }, [conciliar, isAll, setIdEmpresa, pagamentosConciliar]);

  function validateCheckbox(trans) {
    if (trans.type !== 'ticket') {
      if (trans.statusPayment === 'success' && trans.statusReceipt === 'waiting') {
        if (!idEmpresa) return true;
        return (idEmpresa && trans.companyId === idEmpresa);
      }
    }

    // if (trans.Ticket.length > 0) {
    //   return (Number(trans.Ticket[0].ticketPaymentStatus === 2)
    //   && Number(trans.statusPayment) === 2 && Number(trans.receivedStatus === 1))
    //   || (idEmpresa && trans.id_company === idEmpresa);
    // }

    return false;
  }

  return (
    <tr key={transaction.id}>

      <td id="td-checkbox">
        <div id="container-checkbox">
          <input
            type="checkbox"
            disabled={!validateCheckbox(transaction)}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
        </div>
      </td>

      <td>
        <div>
          {String(transaction.code).substring(0, 7)}
        </div>
      </td>

      <td>
        <div>
          {definirFormaPagamento(transaction.transactionType)}
        </div>
      </td>

      <td>
        <div>
          {formatedDate(transaction.createdAt)}
        </div>
      </td>

      <td>
        <div>
          {defineStatus(transaction.statusPayment)}
        </div>
      </td>
      <td>
        <div id="pago-nao-pago">
          <Image src={defineIconConciliation(transaction)} />
        </div>
      </td>

      {isAll && (
        <td>
          <div>
            {transaction.companyFantasyName}
          </div>
        </td>
      )}

      <td>
        <div>
          {`${transaction.numberInstallments} x de R$ ${Number(transaction.amountPerInstallments).toFixed(2)}`}
        </div>
      </td>

      <td>
        <div>
          {`R$ ${Number(transaction.amountTotal).toFixed(2)}`}
        </div>
      </td>

      <td>
        <div>
          {`R$ ${Number(transaction.amountReceivable).toFixed(2)}`}
        </div>
      </td>

      <td>
        <div>
          {transaction.statusPayment === 'success' ? `R$ ${transaction.amountGain}` : 'R$ 0.00'}
        </div>
      </td>

      {!isAll && (
        <>
          <td>
            <div>
              {transaction.numberInstallments}
            </div>

          </td>

          <td>
            <div>
              {`R$ ${Number(transaction.amountPerInstallments).toFixed(2)}`}
            </div>
          </td>
        </>
      )}
    </tr>
  );
}
