import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import api from '../../services/api';

import { RowButtonAdd, DocumentoImg, ColBox } from './styles';

import ModalFormDocumentosEmpresas from '../../components/ModalFormDocumentosEmpresas';
import ModalLoad from '../../components/ModalLoad';
import PageBase from '../../components/PageBase';

export default function Componente({ match, location: { state } }) {
  const [idEmpresa] = useState(match.params.id);
  const [empresa] = useState(state.empresa);
  const [comprovanteBancario, setComprovanteBancario] = useState('');
  const [comprovanteEndereco, setComprovanteEndereco] = useState('');
  const [contratoSocial, setContratoSocial] = useState('');
  const [cartaoCnpj, setCartaoCnpj] = useState('');
  const [documentoFoto, setDocumentoFoto] = useState('');
  const [documentosEmpresa, setDocumentosEmpresa] = useState();
  const [showFormDocumentos, setShowFormDocumentos] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/empresas/${idEmpresa}/documentos`);
    if (data.documentos) {
      setDocumentosEmpresa(data.documentos);
      setContratoSocial(data.documentos.contrato_social);
      setComprovanteBancario(data.documentos.comprovante_bancario);
      setComprovanteEndereco(data.documentos.comprovante_endereco);
      setCartaoCnpj(data.documentos.cartao_cnpj);
      setDocumentoFoto(data.documentos.documento_foto);
    }
    setLoading(false);
  }, [idEmpresa]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <PageBase>
      <ModalLoad show={loading} />
      <ModalFormDocumentosEmpresas
        show={showFormDocumentos}
        close={() => {
          setShowFormDocumentos(false);
          loadData();
        }}
        cancel={() => setShowFormDocumentos(false)}
        tipoDocumento="bancario"
        empresa={empresa}
      />

      <Row style={{ marginTop: '30px' }}>
        <Col>
          <RowButtonAdd>
            <Col>
              <Row>
                <Col>
                  <h2>Documentos</h2>
                </Col>
                <Button
                  onClick={() => setShowFormDocumentos(true)}
                  variant="success"
                  disabled={!!documentosEmpresa}
                >
                  Adicionar
                </Button>
              </Row>
              <hr style={{ margin: '5px' }} />
            </Col>
          </RowButtonAdd>
        </Col>
      </Row>
      {documentosEmpresa ? (
        <Row style={{ marginTop: '20px' }}>
          <ColBox>
            <Row>
              <Col style={{ display: contratoSocial !== null && String(empresa.tipo).toUpperCase() === 'J' ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                {contratoSocial !== null && (
                  <>
                    <h5 style={{ textAlign: 'center' }}>Contrato Social</h5>
                    {contratoSocial && (
                      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href={contratoSocial} target="_blank" rel="noopener noreferrer">
                          <DocumentoImg src={contratoSocial} />
                        </a>
                      </Col>
                    )}
                  </>
                ) }
              </Col>
              <Col style={{ display: comprovanteBancario !== null ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                {comprovanteBancario !== null && (
                  <>
                    <h5 style={{ textAlign: 'center' }}>Comprovante Bancário</h5>
                    {comprovanteBancario && (
                      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href={comprovanteBancario} target="_blank" rel="noopener noreferrer">
                          <DocumentoImg src={comprovanteBancario} />
                        </a>
                      </Col>
                    ) }
                  </>
                ) }
              </Col>
              <Col style={{ display: cartaoCnpj !== null && String(empresa.tipo).toUpperCase() === 'J' ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                {cartaoCnpj !== null && (
                  <>
                    <h5 style={{ textAlign: 'center' }}>Cartão CNPJ</h5>
                    {cartaoCnpj && (
                      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href={cartaoCnpj} target="_blank" rel="noopener noreferrer">
                          <DocumentoImg src={cartaoCnpj} />
                        </a>
                      </Col>
                    ) }
                  </>
                )}
              </Col>
              <Col style={{ display: documentoFoto !== null && String(empresa.tipo).toUpperCase() === 'F' ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                <h5 style={{ textAlign: 'center' }}>Documento Foto</h5>
                {documentoFoto !== null && (
                  <>
                    {documentoFoto && (
                      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href={documentoFoto} target="_blank" rel="noopener noreferrer">
                          <DocumentoImg src={documentoFoto} />
                        </a>
                      </Col>
                    ) }
                  </>
                ) }
              </Col>
              <Col style={{ display: comprovanteEndereco !== null ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                <h5 style={{ textAlign: 'center' }}>Comprovante Endereço</h5>
                {comprovanteEndereco !== null && (
                  <>
                    {comprovanteEndereco && (
                      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <a href={comprovanteEndereco} target="_blank" rel="noopener noreferrer">
                          <DocumentoImg src={comprovanteEndereco} />
                        </a>
                      </Col>
                    ) }
                  </>
                ) }
              </Col>
            </Row>
          </ColBox>
        </Row>

      ) : (
        <Row style={{ marginTop: '25px' }}>
          <h2
            style={{ textAlign: 'center', width: '100%' }}
          >
            Sem documentos cadastrados
          </h2>
        </Row>
      )}
    </PageBase>
  );
}
