import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status < 500,
});

api.interceptors.request.use(async (req) => {
  // if (req.data) console.log(req.data);
  const token = getToken();

  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

api.interceptors.response.use((response) => {
  if (response.status === 401 && window.location.pathname !== '/') {
    window.location.href = '/';
  }

  return response;
});

export default api;
