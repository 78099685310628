import React from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { ColBox } from './styles';

import FormHelp from '../FormHelp';

export default function BoxDataEmpresa({
  children, editar, setEditar, onSubmit, onDelete,
}) {
  return (
    <ColBox>
      <FormHelp onSubmit={onSubmit}>
        {children}
        {onSubmit && (
          <>
            <Col>
              <Button
                size="sm"
                variant="primary"
                style={{
                  width: '100px',
                  display: editar ? 'none' : 'block',
                }}
                onClick={() => setEditar(true)}
              >
                Editar
              </Button>
              <Button
                size="sm"
                variant="info"
                style={{
                  display: !editar ? 'none' : 'block',
                }}
                type="submit"
              >
                Salvar Alteração
              </Button>
            </Col>

          </>
        )}
        {onDelete && (
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={onDelete}
                variant="danger"
              >
                Excluir
              </Button>
            </Col>
          </Row>
        )}
      </FormHelp>
    </ColBox>
  );
}
