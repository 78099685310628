import React, { useState } from 'react';
import {
  Row, Form, Col, Image, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './styles.css';

import PageBase from '../../components/PageBase';

import Pasta from '../../assets/pasta.svg';
import search from '../../assets/search.svg';
import { useFetchCompanies } from '../../hooks/useFetchCompanies';

export default function Empresas() {
  const [razao, setRazao] = useState('');

  const { companies: empresas, loading } = useFetchCompanies({ likeSocialReason: razao });

  return (
    <PageBase>
      <Row>
        <Col>
          <h1>Empresas</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Buscar empresa</Form.Label>
                  <Form.Control size="sm" value={razao} onChange={(e) => setRazao(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  height: '80%',
                }}
                >
                  <button type="submit" id="btn-search">
                    <Image src={search} />
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row id="box-empresas">
        <Col>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              {empresas.map((empresa) => (
                <div key={empresa.id}>
                  <Row style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                    <Col id="razao" md={8}>
                      <h2>{empresa.socialReason}</h2>
                    </Col>
                    <Col>
                      <Link to={`/empresas/${empresa.id}`}>
                        <Image src={Pasta} />
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </PageBase>
  );
}
