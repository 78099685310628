import React, { useState } from 'react';
import Lottie from 'react-lottie';
import {
  Modal, Row, Form, Button,
} from 'react-bootstrap';

import Axios from 'axios';
import api from '../../services/api';

import { ColError, TextError } from './styles';

import FormHelp from '../FormHelp';
import cadLoading from '../../animations/cadLoading.json';

export default function Componente({
  empresa, cancel, close, loadData, ...rest
}) {
  const [comprovanteBancario, setComprovanteBancario] = useState();
  const [contratoSocial, setContratoSocial] = useState();
  const [comprovanteEndereco, setComprovanteEndereco] = useState();
  const [cartaoCnpj, setCartaoCnpj] = useState();
  const [documentoFoto, setDocumentoFoto] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    if (!comprovanteBancario) {
      return alert('Falta comprovante bancario'); //eslint-disable-line
    }
    if (!comprovanteEndereco) {
      return alert('Falta comprovante endereco'); //eslint-disable-line
    }

    const body = new FormData();
    if (String(empresa.tipo) === 'J') {
      if (!contratoSocial) {
        return alert('Falta contrato social'); //eslint-disable-line
      }
      if (!cartaoCnpj) {
        return alert('Falta contrato social'); //eslint-disable-line
      }
      body.append('contrato-social', contratoSocial);
      body.append('cartao-cnpj', cartaoCnpj);
    } else {
      if (!documentoFoto) return alert('Falta um documento com foto'); // eslint-disable-line
      body.append('documento-foto', documentoFoto);
    }

    body.append('type', empresa.tipo);
    body.append('comprovante-endereco', comprovanteEndereco);
    body.append('comprovante-bancario', comprovanteBancario);

    setLoading(true);
    try {
      // const url = 'http://localhost:5000';
      const url = 'https://upload-files-sleep-help.herokuapp.com';
      const { data, status } = await Axios.post(`${url}/helpmaney/documents`, body, {
        params: {
          nameCompany: empresa.nome_fantasia,
        },
      });

      if (status === 200) {
        const { status: st } = await api.post(`/empresas/${empresa.id}/Documentos`, {
          ...data,
        });
        if (st !== 201) {
          setLoading(false);
          return setError('Houve um erro inesperado, tente novamente!');
        }
      }
      setLoading(false);
      return close();
    } catch (err) {
      setLoading(false);
      return setError('Houve um erro inesperado, tente novamente!');
    }
  }

  return (
    <Modal
      {...rest}
      centered
      size="lg"
    >
      <FormHelp onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Usuários ${empresa.razao}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {String(empresa.tipo) === 'J' ? (
            <>
              <Form.Row style={{ marginTop: '25px' }}>
                <Form.Group style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <h4>Contrato Social</h4>
                  <Form.File
                    accept="image/png, image/jpg, application/pdf"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setContratoSocial(file);
                    }}
                    size="sm"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row style={{ marginTop: '25px' }}>
                <Form.Group style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <h4>Cartão CNPJ</h4>
                  <Form.File
                    accept="image/png, image/jpg, application/pdf"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setCartaoCnpj(file);
                    }}
                    size="sm"
                  />
                </Form.Group>
              </Form.Row>
            </>
          ) : (
            <Form.Row style={{ marginTop: '25px' }}>
              <Form.Group style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <h4>Documento com Foto</h4>
                <Form.File
                  accept="image/png, image/jpg, application/pdf"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setDocumentoFoto(file);
                  }}
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row style={{ marginTop: '25px' }}>
            <Form.Group style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <h4>Comprovante Bancario</h4>
              <Form.File
                accept="image/png, image/jpg, application/pdf"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  setComprovanteBancario(file);
                }}
                size="sm"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ marginTop: '25px' }}>
            <Form.Group style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <h4>Comprovante de Endereço</h4>
              <Form.File
                accept="image/png, image/jpg, application/pdf"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setComprovanteEndereco(file);
                }}
                size="sm"
              />
            </Form.Group>
          </Form.Row>
          <Row>
            <ColError>
              <TextError>{error}</TextError>
            </ColError>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setComprovanteBancario({});
              setContratoSocial({});
              setComprovanteEndereco({});
              cancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="info"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Lottie
                options={{
                  animationData: cadLoading,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
                height={25}
                width={80}
              />
            ) : ('Cadastrar')}

          </Button>
        </Modal.Footer>
      </FormHelp>
    </Modal>
  );
}
