import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import ModalFormDadosBancarios from '../../components/ModalFormDadosBancarios';
import api from '../../services/api';

import BoxDadosBancarios from '../../components/BoxDadosBancarios';
import ModalLoad from '../../components/ModalLoad';
import PageBase from '../../components/PageBase';
import { RowButtonAdd } from '../DadosEmpresa/styles';

export default function Componente({ match, location: { state } }) {
  const idEmpresa = match.params.id;
  const { empresa } = state;
  const [dadosBancarios, setDadosBancarios] = useState(state.empresa.dataBank);
  const [showFormDadosBancarios, setShowFormDadosBancarios] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(empresa);

  const loadData = useCallback(async () => {
    setLoading(true);
    const { data, status } = await api.get(`/companies/${idEmpresa}`);
    if (status === 200) {
      setDadosBancarios(data.dataBank);
    }
    setLoading(false);
  }, [idEmpresa]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <PageBase>
      <ModalLoad show={loading} />
      <ModalFormDadosBancarios
        show={showFormDadosBancarios}
        close={() => {
          loadData();
          setShowFormDadosBancarios(false);
        }}
        cancel={() => setShowFormDadosBancarios(false)}
        empresa={empresa}
      />
      <Row>
        <Col>
          <RowButtonAdd>
            <Col>
              <Row>
                <Col>
                  <h2>Dados Bancários</h2>
                </Col>
                <Button
                  variant="success"
                  onClick={() => setShowFormDadosBancarios(true)}
                >
                  {dadosBancarios ? 'Atualizar' : 'Adicionar'}
                </Button>
              </Row>
              <hr style={{ margin: '5px' }} />
            </Col>
          </RowButtonAdd>
          {dadosBancarios ? (
            <>
              <Row style={{ marginTop: '30px' }}>
                <BoxDadosBancarios
                  dadosBancarios={dadosBancarios}
                  empresa={empresa}
                  loadData={loadData}
                />
              </Row>
            </>
          ) : (
            <Row style={{ marginTop: '25px' }}>
              <h2 style={{ textAlign: 'center', width: '100%' }}>Sem dados bancarios cadastrados</h2>
            </Row>
          )}
        </Col>
      </Row>
    </PageBase>

  );
}
