import styled from 'styled-components';
import DatePicker from 'react-datepicker';

/* eslint-disable */
export const DateInput = styled(DatePicker)`
  border: 1px solid #ddd;
  padding: 2px;
  padding-left: 10px;
  color: #777;
  border-radius: 3px;
`;
