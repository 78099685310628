import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
// import { Container } from './styles';

import pdf from '../../assets/pdf.svg';

function ModalArquivos({ files, onHide, ...rest }) {
  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>Arquivos</Modal.Header>
      <Modal.Body>
        {files ? (
          <>
            {files.length > 0 ? files.map((file) => (
              <>
                {file && (
                <a href={file} target="_blank" rel="noopener noreferrer">
                  <Image
                    style={{ height: '80px', margin: '10px' }}
                    src={file.includes('.pdf') ? pdf : file}
                    alt={file}
                  />
                </a>
                )}
              </>
            )) : (
              <p>Nenhum arquivo foi enviado para este pagamento</p>
            )}
          </>
        ) : (
          <p>Nenhum arquivo foi enviado para este pagamento</p>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalArquivos;
