import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const FormHelp = styled(Form)`
  label{
    margin: 0;
  }
`;

export default FormHelp;
