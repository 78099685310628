import React, { useState } from 'react';
import Lottie from 'react-lottie';
import {
  Modal, Button, Form, Row, Col,
} from 'react-bootstrap';

import cadLoad from '../../animations/cadLoading.json';
import api from '../../services/api';

import { TextError, Label } from './styles';

export default function ModalConciliacao({
  close, cancel, dados, valor, empresa, transacoes, pagamentos, ...rest
}) {
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  if (Object.keys(dados).length < 1) {
    return <></>;
  }

  async function handleConcialiation() {
    setLoading(true);

    try {
      const { status } = await api.post('/receipts', {
        companyId: empresa.id,
        transactionsIds: transacoes.map((t) => t.id),
        dataBankId: dados.id,
      });

      if (status !== 201) {
        setError('Houve um erro inesperado, tente novamente');
        return setLoading(false);
      }

      setLoading(false);
      return close();
    } catch (err) {
      setError('Houve um erro inesperado, tente novamente');
      return setLoading(false);
    }
  }

  return (
    <Modal
      {...rest}
      centered
      size="xl"
    >
      <>
        <Modal.Header>
          <Modal.Title>{`Conciliação bancária ${empresa.fantasyName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Label>Agência</Label>
                <Form.Control
                  required
                  disabled
                  value={dados.agency}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Label>Número da conta</Label>
                <Form.Control
                  disabled
                  value={dados.account}
                  size="sm"
                />
              </Form.Group>
            </Col>
            {/* <Col>
                <Form.Group>
                  <Label>Natureza</Label>
                  <Form.Control
                    disabled
                    value={dados[dadosIndex].natureza}
                    size="sm"
                  />
                </Form.Group>
              </Col> */}
          </Row>
          <Row>
            {/* <Col md={4}>
                <Form.Group>
                  <Label>CPF/CNPJ</Label>
                  <Form.Control
                    disabled
                    value={dados[dadosIndex].cpf_cnpj_banco}
                    size="sm"
                  />
                </Form.Group>
              </Col> */}
            <Col>
              <Form.Group>
                <Label>Instituição bancária</Label>
                <Form.Control
                  disabled
                  value={`${dados.bank}`}
                  size="sm"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Label>PIX</Label>
                <Form.Control
                  disabled
                  value={`${dados.pix}`}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Label>Valor total</Label>
                <Form.Control
                  disabled
                  value={`R$ ${Number(valor).toFixed(2)}`}
                  size="sm"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <TextError>{error}</TextError>
          </Col>
          <Button
            variant="secondary"
            onClick={() => {
              cancel();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="info"
            type="submit"
            disabled={loading}
            onClick={handleConcialiation}
          >
            {loading ? (
              <Lottie
                options={{
                  animationData: cadLoad,
                  autoplay: true,
                  loop: true,
                  rendererSettings: 'xMidYMid slice',
                }}
                height={25}
                width={80}
              />
            ) : ('Conciliar')}
          </Button>
        </Modal.Footer>

      </>
    </Modal>
  );
}
