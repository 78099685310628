import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

const privateRoute = ({ component: Component, ...rest }) => {
  if (!isAuthenticated()) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

export default privateRoute;
