import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Login from './pages/Login';
import Pagamentos from './pages/Pagamentos';
import DetalhesPagamento from './pages/DetalhesPagamento';
import Parceiros from './pages/Parceiros';
import Empresas from './pages/Empresas';
import Transactions from './pages/Transactions';
// import PageEmpresa from './pages/PageEmpresa';
import DadosEmpresa from './pages/DadosEmpresa';
import DadosBancarios from './pages/DadosBancarios';
import Documentos from './pages/Documentos';
import Conciliacoes from './pages/Conciliacoes';
import Usuarios from './pages/Usuarios';
import CadastroEmpresas from './pages/CadastroEmpresas';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/pagamentos" exact component={Pagamentos} />
      <PrivateRoute path="/pagamentos/:id" exact component={DetalhesPagamento} />
      <PrivateRoute path="/parceiros" component={Parceiros} />
      <PrivateRoute path="/transacoes" exact component={Transactions} />
      <PrivateRoute path="/empresas" exact component={Empresas} />
      <PrivateRoute path="/empresas/:id" exact component={DadosEmpresa} />
      {/* <PrivateRoute path="/empresas/:id/dados" exact component={DadosEmpresa} /> */}
      <PrivateRoute path="/empresas/:id/dados/bancarios" exact component={DadosBancarios} />
      <PrivateRoute path="/empresas/:id/dados/documentos" exact component={Documentos} />
      <PrivateRoute path="/empresas/:id/dados/usuarios" exact component={Usuarios} />
      <PrivateRoute path="/empresas/:id/dados/conciliacoes" exact component={Conciliacoes} />
      <PrivateRoute path="/cadastroempresas" component={CadastroEmpresas} />
    </Switch>
  );
}
