import styled from 'styled-components';
import { Form, Col } from 'react-bootstrap';

export const TextError = styled.p`
  color: red;
  font-weight: bold;
  height: 15px;
`;

export const FormUser = styled(Form)`
  label{
    margin: 0
  }
`;

export const ColError = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
