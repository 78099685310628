import React, { useRef, useState, useEffect } from 'react';
import {
  Container, Image, Card,
} from 'react-bootstrap';
import { logout } from '../../services/auth';

import './styles.css';
import logo from '../../assets/logo.svg';
import user from '../../assets/empregado.svg';
import anterior from '../../assets/anterior.svg';
import sair from '../../assets/sair.svg';

export default function Header() {
  const [empresaLogged] = useState('HelpManey Admin');
  const [usernameLogged, setUsernameLogged] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef();

  useEffect(() => {
    setUsernameLogged(localStorage.getItem('userlogged'));
  }, []);

  function exibirOptions() {
    if (!showOptions) {
      optionsRef.current.classList.add('on');
      setShowOptions(true);
    } else {
      optionsRef.current.classList.remove('on');
      setShowOptions(false);
    }
  }

  return (
    <Container fluid className="header">
      <Image src={logo} style={{ height: '50px' }} />
      <Card className="card-logged">
        <Card.Header id="card-hea">
          <Image src={user} style={{ height: '35px' }} />
          <div className="texts">
            <p>{empresaLogged}</p>
            <hr />
            <p>{usernameLogged}</p>
          </div>
          <button
            type="button"
            onClick={exibirOptions}
          >
            <Image src={anterior} style={{ height: '15px' }} />
          </button>
        </Card.Header>
      </Card>
      <Card id="card-body" ref={optionsRef}>
        <button
          type="button"
          className="option"
          onClick={() => {
            logout();
            window.location.reload();
          }}
        >
          <Image src={sair} />
          <strong>Sair</strong>
        </button>
      </Card>
    </Container>
  );
}
