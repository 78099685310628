import React from 'react';
import {
  Modal, Button, Row, Col,
} from 'react-bootstrap';

export default function ModalConfirm({
  close, mensagem, onDelete, ...rest
}) {
  return (
    <Modal
      {...rest}
      centered
    >
      <Modal.Body>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <h5>{mensagem}</h5>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
            <Button variant="info" onClick={close}>Cancelar</Button>
            <Button
              variant="danger"
              onClick={() => {
                close();
                onDelete();
              }}
            >
              Deletar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
