import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const Box = styled(Row)`
  margin-top: 50px;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
`;

export const RowBtnSubmit = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;

  button[type='submit']{
    width: 200px;
  }
`;

export const TextError = styled.p`
  color: red;
  margin: 0;
  height: 15px;
  width: 400px;
  text-align: center;
`;
